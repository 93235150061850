import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name package-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMyA5aDE4djEwYTIgMiAwIDAgMS0yIDJINWEyIDIgMCAwIDEtMi0yVjlaIiAvPgogIDxwYXRoIGQ9Im0zIDkgMi40NS00LjlBMiAyIDAgMCAxIDcuMjQgM2g5LjUyYTIgMiAwIDAgMSAxLjggMS4xTDIxIDkiIC8+CiAgPHBhdGggZD0iTTEyIDN2NiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/package-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Package2: IconNode = [
  ['path', { d: 'M3 9h18v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z' }],
  ['path', { d: 'm3 9 2.45-4.9A2 2 0 0 1 7.24 3h9.52a2 2 0 0 1 1.8 1.1L21 9' }],
  ['path', { d: 'M12 3v6' }],
];

export default Package2;
