import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name tree-deciduous
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOCAxOWE0IDQgMCAwIDEtMi4yNC03LjMyQTMuNSAzLjUgMCAwIDEgOSA2LjAzVjZhMyAzIDAgMSAxIDYgMHYuMDRhMy41IDMuNSAwIDAgMSAzLjI0IDUuNjVBNCA0IDAgMCAxIDE2IDE5WiIgLz4KICA8cGF0aCBkPSJNMTIgMTl2MyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/tree-deciduous
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TreeDeciduous: IconNode = [
  [
    'path',
    {
      d: 'M8 19a4 4 0 0 1-2.24-7.32A3.5 3.5 0 0 1 9 6.03V6a3 3 0 1 1 6 0v.04a3.5 3.5 0 0 1 3.24 5.65A4 4 0 0 1 16 19Z',
    },
  ],
  ['path', { d: 'M12 19v3' }],
];

export default TreeDeciduous;
