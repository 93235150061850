import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name lamp-wall-up
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgNGg2bDMgN0g4bDMtN1oiIC8+CiAgPHBhdGggZD0iTTE0IDExdjVhMiAyIDAgMCAxLTIgMkg4IiAvPgogIDxwYXRoIGQ9Ik00IDE1aDJhMiAyIDAgMCAxIDIgMnYyYTIgMiAwIDAgMS0yIDJINHYtNloiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/lamp-wall-up
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const LampWallUp: IconNode = [
  ['path', { d: 'M11 4h6l3 7H8l3-7Z' }],
  ['path', { d: 'M14 11v5a2 2 0 0 1-2 2H8' }],
  ['path', { d: 'M4 15h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H4v-6Z' }],
];

export default LampWallUp;
