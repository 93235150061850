import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name egg-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNi4zOTkgNi4zOTlDNS4zNjIgOC4xNTcgNC42NSAxMC4xODkgNC41IDEyYy0uMzcgNC40MyAxLjI3IDkuOTUgNy41IDEwIDMuMjU2LS4wMjYgNS4yNTktMS41NDcgNi4zNzUtMy42MjUiIC8+CiAgPHBhdGggZD0iTTE5LjUzMiAxMy44NzVBMTQuMDcgMTQuMDcgMCAwIDAgMTkuNSAxMmMtLjM2LTQuMzQtMy45NS05Ljk2LTcuNS0xMC0xLjA0LjAxMi0yLjA4Mi41MDItMy4wNDYgMS4yOTciIC8+CiAgPGxpbmUgeDE9IjIiIHgyPSIyMiIgeTE9IjIiIHkyPSIyMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/egg-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const EggOff: IconNode = [
  [
    'path',
    {
      d: 'M6.399 6.399C5.362 8.157 4.65 10.189 4.5 12c-.37 4.43 1.27 9.95 7.5 10 3.256-.026 5.259-1.547 6.375-3.625',
    },
  ],
  [
    'path',
    {
      d: 'M19.532 13.875A14.07 14.07 0 0 0 19.5 12c-.36-4.34-3.95-9.96-7.5-10-1.04.012-2.082.502-3.046 1.297',
    },
  ],
  ['line', { x1: '2', x2: '22', y1: '2', y2: '22' }],
];

export default EggOff;
