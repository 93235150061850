import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name soup
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMjFhOSA5IDAgMCAwIDktOUgzYTkgOSAwIDAgMCA5IDlaIiAvPgogIDxwYXRoIGQ9Ik03IDIxaDEwIiAvPgogIDxwYXRoIGQ9Ik0xOS41IDEyIDIyIDYiIC8+CiAgPHBhdGggZD0iTTE2LjI1IDNjLjI3LjEuOC41My43NSAxLjM2LS4wNi44My0uOTMgMS4yLTEgMi4wMi0uMDUuNzguMzQgMS4yNC43MyAxLjYyIiAvPgogIDxwYXRoIGQ9Ik0xMS4yNSAzYy4yNy4xLjguNTMuNzQgMS4zNi0uMDUuODMtLjkzIDEuMi0uOTggMi4wMi0uMDYuNzguMzMgMS4yNC43MiAxLjYyIiAvPgogIDxwYXRoIGQ9Ik02LjI1IDNjLjI3LjEuOC41My43NSAxLjM2LS4wNi44My0uOTMgMS4yLTEgMi4wMi0uMDUuNzguMzQgMS4yNC43NCAxLjYyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/soup
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Soup: IconNode = [
  ['path', { d: 'M12 21a9 9 0 0 0 9-9H3a9 9 0 0 0 9 9Z' }],
  ['path', { d: 'M7 21h10' }],
  ['path', { d: 'M19.5 12 22 6' }],
  ['path', { d: 'M16.25 3c.27.1.8.53.75 1.36-.06.83-.93 1.2-1 2.02-.05.78.34 1.24.73 1.62' }],
  ['path', { d: 'M11.25 3c.27.1.8.53.74 1.36-.05.83-.93 1.2-.98 2.02-.06.78.33 1.24.72 1.62' }],
  ['path', { d: 'M6.25 3c.27.1.8.53.75 1.36-.06.83-.93 1.2-1 2.02-.05.78.34 1.24.74 1.62' }],
];

export default Soup;
