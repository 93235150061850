import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name torus
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8ZWxsaXBzZSBjeD0iMTIiIGN5PSIxMSIgcng9IjMiIHJ5PSIyIiAvPgogIDxlbGxpcHNlIGN4PSIxMiIgY3k9IjEyLjUiIHJ4PSIxMCIgcnk9IjguNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/torus
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Torus: IconNode = [
  ['ellipse', { cx: '12', cy: '11', rx: '3', ry: '2' }],
  ['ellipse', { cx: '12', cy: '12.5', rx: '10', ry: '8.5' }],
];

export default Torus;
