import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name folder-cog
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxOCIgY3k9IjE4IiByPSIzIiAvPgogIDxwYXRoIGQ9Ik0xMC4zIDIwSDRhMiAyIDAgMCAxLTItMlY1YTIgMiAwIDAgMSAyLTJoMy45YTIgMiAwIDAgMSAxLjY5LjlsLjgxIDEuMmEyIDIgMCAwIDAgMS42Ny45SDIwYTIgMiAwIDAgMSAyIDJ2My4zIiAvPgogIDxwYXRoIGQ9Im0yMS43IDE5LjQtLjktLjMiIC8+CiAgPHBhdGggZD0ibTE1LjIgMTYuOS0uOS0uMyIgLz4KICA8cGF0aCBkPSJtMTYuNiAyMS43LjMtLjkiIC8+CiAgPHBhdGggZD0ibTE5LjEgMTUuMi4zLS45IiAvPgogIDxwYXRoIGQ9Im0xOS42IDIxLjctLjQtMSIgLz4KICA8cGF0aCBkPSJtMTYuOCAxNS4zLS40LTEiIC8+CiAgPHBhdGggZD0ibTE0LjMgMTkuNiAxLS40IiAvPgogIDxwYXRoIGQ9Im0yMC43IDE2LjggMS0uNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/folder-cog
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FolderCog: IconNode = [
  ['circle', { cx: '18', cy: '18', r: '3' }],
  [
    'path',
    {
      d: 'M10.3 20H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3.9a2 2 0 0 1 1.69.9l.81 1.2a2 2 0 0 0 1.67.9H20a2 2 0 0 1 2 2v3.3',
    },
  ],
  ['path', { d: 'm21.7 19.4-.9-.3' }],
  ['path', { d: 'm15.2 16.9-.9-.3' }],
  ['path', { d: 'm16.6 21.7.3-.9' }],
  ['path', { d: 'm19.1 15.2.3-.9' }],
  ['path', { d: 'm19.6 21.7-.4-1' }],
  ['path', { d: 'm16.8 15.3-.4-1' }],
  ['path', { d: 'm14.3 19.6 1-.4' }],
  ['path', { d: 'm20.7 16.8 1-.4' }],
];

export default FolderCog;
