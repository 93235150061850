import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name flame-kindling
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMmMxIDMgMi41IDMuNSAzLjUgNC41QTUgNSAwIDAgMSAxNyAxMGE1IDUgMCAxIDEtMTAgMGMwLS4zIDAtLjYuMS0uOWEyIDIgMCAxIDAgMy4zLTJDOCA0LjUgMTEgMiAxMiAyWiIgLz4KICA8cGF0aCBkPSJtNSAyMiAxNC00IiAvPgogIDxwYXRoIGQ9Im01IDE4IDE0IDQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/flame-kindling
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FlameKindling: IconNode = [
  [
    'path',
    {
      d: 'M12 2c1 3 2.5 3.5 3.5 4.5A5 5 0 0 1 17 10a5 5 0 1 1-10 0c0-.3 0-.6.1-.9a2 2 0 1 0 3.3-2C8 4.5 11 2 12 2Z',
    },
  ],
  ['path', { d: 'm5 22 14-4' }],
  ['path', { d: 'm5 18 14 4' }],
];

export default FlameKindling;
