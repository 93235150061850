import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name binary
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB4PSIxNCIgeT0iMTQiIHdpZHRoPSI0IiBoZWlnaHQ9IjYiIHJ4PSIyIiAvPgogIDxyZWN0IHg9IjYiIHk9IjQiIHdpZHRoPSI0IiBoZWlnaHQ9IjYiIHJ4PSIyIiAvPgogIDxwYXRoIGQ9Ik02IDIwaDQiIC8+CiAgPHBhdGggZD0iTTE0IDEwaDQiIC8+CiAgPHBhdGggZD0iTTYgMTRoMnY2IiAvPgogIDxwYXRoIGQ9Ik0xNCA0aDJ2NiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/binary
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Binary: IconNode = [
  ['rect', { x: '14', y: '14', width: '4', height: '6', rx: '2' }],
  ['rect', { x: '6', y: '4', width: '4', height: '6', rx: '2' }],
  ['path', { d: 'M6 20h4' }],
  ['path', { d: 'M14 10h4' }],
  ['path', { d: 'M6 14h2v6' }],
  ['path', { d: 'M14 4h2v6' }],
];

export default Binary;
