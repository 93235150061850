import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name list-music
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEgMTVWNiIgLz4KICA8cGF0aCBkPSJNMTguNSAxOGEyLjUgMi41IDAgMSAwIDAtNSAyLjUgMi41IDAgMCAwIDAgNVoiIC8+CiAgPHBhdGggZD0iTTEyIDEySDMiIC8+CiAgPHBhdGggZD0iTTE2IDZIMyIgLz4KICA8cGF0aCBkPSJNMTIgMThIMyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/list-music
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ListMusic: IconNode = [
  ['path', { d: 'M21 15V6' }],
  ['path', { d: 'M18.5 18a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z' }],
  ['path', { d: 'M12 12H3' }],
  ['path', { d: 'M16 6H3' }],
  ['path', { d: 'M12 18H3' }],
];

export default ListMusic;
