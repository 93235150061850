import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name non-binary
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMnYxMCIgLz4KICA8cGF0aCBkPSJtOSA0IDYgNCIgLz4KICA8cGF0aCBkPSJtOSA4IDYtNCIgLz4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjE3IiByPSI1IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/non-binary
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const NonBinary: IconNode = [
  ['path', { d: 'M12 2v10' }],
  ['path', { d: 'm9 4 6 4' }],
  ['path', { d: 'm9 8 6-4' }],
  ['circle', { cx: '12', cy: '17', r: '5' }],
];

export default NonBinary;
