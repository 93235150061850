import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name spell-check-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtNiAxNiA2LTEyIDYgMTIiIC8+CiAgPHBhdGggZD0iTTggMTJoOCIgLz4KICA8cGF0aCBkPSJNNCAyMWMxLjEgMCAxLjEtMSAyLjMtMXMxLjEgMSAyLjMgMWMxLjEgMCAxLjEtMSAyLjMtMSAxLjEgMCAxLjEgMSAyLjMgMSAxLjEgMCAxLjEtMSAyLjMtMSAxLjEgMCAxLjEgMSAyLjMgMSAxLjEgMCAxLjEtMSAyLjMtMSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/spell-check-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const SpellCheck2: IconNode = [
  ['path', { d: 'm6 16 6-12 6 12' }],
  ['path', { d: 'M8 12h8' }],
  [
    'path',
    {
      d: 'M4 21c1.1 0 1.1-1 2.3-1s1.1 1 2.3 1c1.1 0 1.1-1 2.3-1 1.1 0 1.1 1 2.3 1 1.1 0 1.1-1 2.3-1 1.1 0 1.1 1 2.3 1 1.1 0 1.1-1 2.3-1',
    },
  ],
];

export default SpellCheck2;
