import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name user-search
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMCIgY3k9IjciIHI9IjQiIC8+CiAgPHBhdGggZD0iTTEwLjMgMTVIN2E0IDQgMCAwIDAtNCA0djIiIC8+CiAgPGNpcmNsZSBjeD0iMTciIGN5PSIxNyIgcj0iMyIgLz4KICA8cGF0aCBkPSJtMjEgMjEtMS45LTEuOSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/user-search
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const UserSearch: IconNode = [
  ['circle', { cx: '10', cy: '7', r: '4' }],
  ['path', { d: 'M10.3 15H7a4 4 0 0 0-4 4v2' }],
  ['circle', { cx: '17', cy: '17', r: '3' }],
  ['path', { d: 'm21 21-1.9-1.9' }],
];

export default UserSearch;
