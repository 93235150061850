import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name signal
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAyMGguMDEiIC8+CiAgPHBhdGggZD0iTTcgMjB2LTQiIC8+CiAgPHBhdGggZD0iTTEyIDIwdi04IiAvPgogIDxwYXRoIGQ9Ik0xNyAyMFY4IiAvPgogIDxwYXRoIGQ9Ik0yMiA0djE2IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/signal
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Signal: IconNode = [
  ['path', { d: 'M2 20h.01' }],
  ['path', { d: 'M7 20v-4' }],
  ['path', { d: 'M12 20v-8' }],
  ['path', { d: 'M17 20V8' }],
  ['path', { d: 'M22 4v16' }],
];

export default Signal;
