import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name fuel
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iMyIgeDI9IjE1IiB5MT0iMjIiIHkyPSIyMiIgLz4KICA8bGluZSB4MT0iNCIgeDI9IjE0IiB5MT0iOSIgeTI9IjkiIC8+CiAgPHBhdGggZD0iTTE0IDIyVjRhMiAyIDAgMCAwLTItMkg2YTIgMiAwIDAgMC0yIDJ2MTgiIC8+CiAgPHBhdGggZD0iTTE0IDEzaDJhMiAyIDAgMCAxIDIgMnYyYTIgMiAwIDAgMCAyIDJhMiAyIDAgMCAwIDItMlY5LjgzYTIgMiAwIDAgMC0uNTktMS40MkwxOCA1IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/fuel
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Fuel: IconNode = [
  ['line', { x1: '3', x2: '15', y1: '22', y2: '22' }],
  ['line', { x1: '4', x2: '14', y1: '9', y2: '9' }],
  ['path', { d: 'M14 22V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v18' }],
  [
    'path',
    { d: 'M14 13h2a2 2 0 0 1 2 2v2a2 2 0 0 0 2 2a2 2 0 0 0 2-2V9.83a2 2 0 0 0-.59-1.42L18 5' },
  ],
];

export default Fuel;
