import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name gavel
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTQuNSAxMi41LTggOGEyLjExOSAyLjExOSAwIDEgMS0zLTNsOC04IiAvPgogIDxwYXRoIGQ9Im0xNiAxNiA2LTYiIC8+CiAgPHBhdGggZD0ibTggOCA2LTYiIC8+CiAgPHBhdGggZD0ibTkgNyA4IDgiIC8+CiAgPHBhdGggZD0ibTIxIDExLTgtOCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/gavel
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Gavel: IconNode = [
  ['path', { d: 'm14.5 12.5-8 8a2.119 2.119 0 1 1-3-3l8-8' }],
  ['path', { d: 'm16 16 6-6' }],
  ['path', { d: 'm8 8 6-6' }],
  ['path', { d: 'm9 7 8 8' }],
  ['path', { d: 'm21 11-8-8' }],
];

export default Gavel;
