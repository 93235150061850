import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name unfold-vertical
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMjJ2LTYiIC8+CiAgPHBhdGggZD0iTTEyIDhWMiIgLz4KICA8cGF0aCBkPSJNNCAxMkgyIiAvPgogIDxwYXRoIGQ9Ik0xMCAxMkg4IiAvPgogIDxwYXRoIGQ9Ik0xNiAxMmgtMiIgLz4KICA8cGF0aCBkPSJNMjIgMTJoLTIiIC8+CiAgPHBhdGggZD0ibTE1IDE5LTMgMy0zLTMiIC8+CiAgPHBhdGggZD0ibTE1IDUtMy0zLTMgMyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/unfold-vertical
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const UnfoldVertical: IconNode = [
  ['path', { d: 'M12 22v-6' }],
  ['path', { d: 'M12 8V2' }],
  ['path', { d: 'M4 12H2' }],
  ['path', { d: 'M10 12H8' }],
  ['path', { d: 'M16 12h-2' }],
  ['path', { d: 'M22 12h-2' }],
  ['path', { d: 'm15 19-3 3-3-3' }],
  ['path', { d: 'm15 5-3-3-3 3' }],
];

export default UnfoldVertical;
