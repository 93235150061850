import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name align-start-vertical
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iOSIgaGVpZ2h0PSI2IiB4PSI2IiB5PSIxNCIgcng9IjIiIC8+CiAgPHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjYiIHg9IjYiIHk9IjQiIHJ4PSIyIiAvPgogIDxwYXRoIGQ9Ik0yIDJ2MjAiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/align-start-vertical
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const AlignStartVertical: IconNode = [
  ['rect', { width: '9', height: '6', x: '6', y: '14', rx: '2' }],
  ['rect', { width: '16', height: '6', x: '6', y: '4', rx: '2' }],
  ['path', { d: 'M2 2v20' }],
];

export default AlignStartVertical;
