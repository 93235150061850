import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name tower-control
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTguMiAxMi4yNyAyMCA2SDRsMS44IDYuMjdhMSAxIDAgMCAwIC45NS43M2gxMC41YTEgMSAwIDAgMCAuOTYtLjczWiIgLz4KICA8cGF0aCBkPSJNOCAxM3Y5IiAvPgogIDxwYXRoIGQ9Ik0xNiAyMnYtOSIgLz4KICA8cGF0aCBkPSJtOSA2IDEgNyIgLz4KICA8cGF0aCBkPSJtMTUgNi0xIDciIC8+CiAgPHBhdGggZD0iTTEyIDZWMiIgLz4KICA8cGF0aCBkPSJNMTMgMmgtMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/tower-control
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TowerControl: IconNode = [
  ['path', { d: 'M18.2 12.27 20 6H4l1.8 6.27a1 1 0 0 0 .95.73h10.5a1 1 0 0 0 .96-.73Z' }],
  ['path', { d: 'M8 13v9' }],
  ['path', { d: 'M16 22v-9' }],
  ['path', { d: 'm9 6 1 7' }],
  ['path', { d: 'm15 6-1 7' }],
  ['path', { d: 'M12 6V2' }],
  ['path', { d: 'M13 2h-2' }],
];

export default TowerControl;
