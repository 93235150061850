import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name bath
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgNCA4IDYiIC8+CiAgPHBhdGggZD0iTTE3IDE5djIiIC8+CiAgPHBhdGggZD0iTTIgMTJoMjAiIC8+CiAgPHBhdGggZD0iTTcgMTl2MiIgLz4KICA8cGF0aCBkPSJNOSA1IDcuNjIxIDMuNjIxQTIuMTIxIDIuMTIxIDAgMCAwIDQgNXYxMmEyIDIgMCAwIDAgMiAyaDEyYTIgMiAwIDAgMCAyLTJ2LTUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/bath
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Bath: IconNode = [
  ['path', { d: 'M10 4 8 6' }],
  ['path', { d: 'M17 19v2' }],
  ['path', { d: 'M2 12h20' }],
  ['path', { d: 'M7 19v2' }],
  ['path', { d: 'M9 5 7.621 3.621A2.121 2.121 0 0 0 4 5v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-5' }],
];

export default Bath;
