import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name corner-left-up
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cG9seWxpbmUgcG9pbnRzPSIxNCA5IDkgNCA0IDkiIC8+CiAgPHBhdGggZD0iTTIwIDIwaC03YTQgNCAwIDAgMS00LTRWNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/corner-left-up
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CornerLeftUp: IconNode = [
  ['polyline', { points: '14 9 9 4 4 9' }],
  ['path', { d: 'M20 20h-7a4 4 0 0 1-4-4V4' }],
];

export default CornerLeftUp;
