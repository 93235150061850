import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name bluetooth-searching
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtNyA3IDEwIDEwLTUgNVYybDUgNUw3IDE3IiAvPgogIDxwYXRoIGQ9Ik0yMC44MyAxNC44M2E0IDQgMCAwIDAgMC01LjY2IiAvPgogIDxwYXRoIGQ9Ik0xOCAxMmguMDEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/bluetooth-searching
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BluetoothSearching: IconNode = [
  ['path', { d: 'm7 7 10 10-5 5V2l5 5L7 17' }],
  ['path', { d: 'M20.83 14.83a4 4 0 0 0 0-5.66' }],
  ['path', { d: 'M18 12h.01' }],
];

export default BluetoothSearching;
