import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name signature
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMjEgMTctMi4xNTYtMS44NjhBLjUuNSAwIDAgMCAxOCAxNS41di41YTEgMSAwIDAgMS0xIDFoLTJhMSAxIDAgMCAxLTEtMWMwLTIuNTQ1LTMuOTkxLTMuOTctOC41LTRhMSAxIDAgMCAwIDAgNWM0LjE1MyAwIDQuNzQ1LTExLjI5NSA1LjcwOC0xMy41YTIuNSAyLjUgMCAxIDEgMy4zMSAzLjI4NCIgLz4KICA8cGF0aCBkPSJNMyAyMWgxOCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/signature
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Signature: IconNode = [
  [
    'path',
    {
      d: 'm21 17-2.156-1.868A.5.5 0 0 0 18 15.5v.5a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1c0-2.545-3.991-3.97-8.5-4a1 1 0 0 0 0 5c4.153 0 4.745-11.295 5.708-13.5a2.5 2.5 0 1 1 3.31 3.284',
    },
  ],
  ['path', { d: 'M3 21h18' }],
];

export default Signature;
