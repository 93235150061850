import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name construction
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB4PSIyIiB5PSI2IiB3aWR0aD0iMjAiIGhlaWdodD0iOCIgcng9IjEiIC8+CiAgPHBhdGggZD0iTTE3IDE0djciIC8+CiAgPHBhdGggZD0iTTcgMTR2NyIgLz4KICA8cGF0aCBkPSJNMTcgM3YzIiAvPgogIDxwYXRoIGQ9Ik03IDN2MyIgLz4KICA8cGF0aCBkPSJNMTAgMTQgMi4zIDYuMyIgLz4KICA8cGF0aCBkPSJtMTQgNiA3LjcgNy43IiAvPgogIDxwYXRoIGQ9Im04IDYgOCA4IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/construction
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Construction: IconNode = [
  ['rect', { x: '2', y: '6', width: '20', height: '8', rx: '1' }],
  ['path', { d: 'M17 14v7' }],
  ['path', { d: 'M7 14v7' }],
  ['path', { d: 'M17 3v3' }],
  ['path', { d: 'M7 3v3' }],
  ['path', { d: 'M10 14 2.3 6.3' }],
  ['path', { d: 'm14 6 7.7 7.7' }],
  ['path', { d: 'm8 6 8 8' }],
];

export default Construction;
