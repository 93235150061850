import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name house-plus
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTMuMjIgMi40MTZhMiAyIDAgMCAwLTIuNTExLjA1N2wtNyA1Ljk5OUEyIDIgMCAwIDAgMyAxMHY5YTIgMiAwIDAgMCAyIDJoMTRhMiAyIDAgMCAwIDItMnYtNy4zNTQiIC8+CiAgPHBhdGggZD0iTTE1IDIxdi04YTEgMSAwIDAgMC0xLTFoLTRhMSAxIDAgMCAwLTEgMXY4IiAvPgogIDxwYXRoIGQ9Ik0xNSA2aDYiIC8+CiAgPHBhdGggZD0iTTE4IDN2NiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/house-plus
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const HousePlus: IconNode = [
  [
    'path',
    {
      d: 'M13.22 2.416a2 2 0 0 0-2.511.057l-7 5.999A2 2 0 0 0 3 10v9a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7.354',
    },
  ],
  ['path', { d: 'M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8' }],
  ['path', { d: 'M15 6h6' }],
  ['path', { d: 'M18 3v6' }],
];

export default HousePlus;
