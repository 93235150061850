import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name chart-network
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTMuMTEgNy42NjQgMS43OCAyLjY3MiIgLz4KICA8cGF0aCBkPSJtMTQuMTYyIDEyLjc4OC0zLjMyNCAxLjQyNCIgLz4KICA8cGF0aCBkPSJtMjAgNC02LjA2IDEuNTE1IiAvPgogIDxwYXRoIGQ9Ik0zIDN2MTZhMiAyIDAgMCAwIDIgMmgxNiIgLz4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjYiIHI9IjIiIC8+CiAgPGNpcmNsZSBjeD0iMTYiIGN5PSIxMiIgcj0iMiIgLz4KICA8Y2lyY2xlIGN4PSI5IiBjeT0iMTUiIHI9IjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/chart-network
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ChartNetwork: IconNode = [
  ['path', { d: 'm13.11 7.664 1.78 2.672' }],
  ['path', { d: 'm14.162 12.788-3.324 1.424' }],
  ['path', { d: 'm20 4-6.06 1.515' }],
  ['path', { d: 'M3 3v16a2 2 0 0 0 2 2h16' }],
  ['circle', { cx: '12', cy: '6', r: '2' }],
  ['circle', { cx: '16', cy: '12', r: '2' }],
  ['circle', { cx: '9', cy: '15', r: '2' }],
];

export default ChartNetwork;
