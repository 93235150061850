import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name circle-fading-plus
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMmExMCAxMCAwIDAgMSA3LjM4IDE2Ljc1IiAvPgogIDxwYXRoIGQ9Ik0xMiA4djgiIC8+CiAgPHBhdGggZD0iTTE2IDEySDgiIC8+CiAgPHBhdGggZD0iTTIuNSA4Ljg3NWExMCAxMCAwIDAgMC0uNSAzIiAvPgogIDxwYXRoIGQ9Ik0yLjgzIDE2YTEwIDEwIDAgMCAwIDIuNDMgMy40IiAvPgogIDxwYXRoIGQ9Ik00LjYzNiA1LjIzNWExMCAxMCAwIDAgMSAuODkxLS44NTciIC8+CiAgPHBhdGggZD0iTTguNjQ0IDIxLjQyYTEwIDEwIDAgMCAwIDcuNjMxLS4zOCIgLz4KPC9zdmc+) - https://lucide.dev/icons/circle-fading-plus
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CircleFadingPlus: IconNode = [
  ['path', { d: 'M12 2a10 10 0 0 1 7.38 16.75' }],
  ['path', { d: 'M12 8v8' }],
  ['path', { d: 'M16 12H8' }],
  ['path', { d: 'M2.5 8.875a10 10 0 0 0-.5 3' }],
  ['path', { d: 'M2.83 16a10 10 0 0 0 2.43 3.4' }],
  ['path', { d: 'M4.636 5.235a10 10 0 0 1 .891-.857' }],
  ['path', { d: 'M8.644 21.42a10 10 0 0 0 7.631-.38' }],
];

export default CircleFadingPlus;
