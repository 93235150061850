import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name notepad-text-dashed
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOCAydjQiIC8+CiAgPHBhdGggZD0iTTEyIDJ2NCIgLz4KICA8cGF0aCBkPSJNMTYgMnY0IiAvPgogIDxwYXRoIGQ9Ik0xNiA0aDJhMiAyIDAgMCAxIDIgMnYyIiAvPgogIDxwYXRoIGQ9Ik0yMCAxMnYyIiAvPgogIDxwYXRoIGQ9Ik0yMCAxOHYyYTIgMiAwIDAgMS0yIDJoLTEiIC8+CiAgPHBhdGggZD0iTTEzIDIyaC0yIiAvPgogIDxwYXRoIGQ9Ik03IDIySDZhMiAyIDAgMCAxLTItMnYtMiIgLz4KICA8cGF0aCBkPSJNNCAxNHYtMiIgLz4KICA8cGF0aCBkPSJNNCA4VjZhMiAyIDAgMCAxIDItMmgyIiAvPgogIDxwYXRoIGQ9Ik04IDEwaDYiIC8+CiAgPHBhdGggZD0iTTggMTRoOCIgLz4KICA8cGF0aCBkPSJNOCAxOGg1IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/notepad-text-dashed
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const NotepadTextDashed: IconNode = [
  ['path', { d: 'M8 2v4' }],
  ['path', { d: 'M12 2v4' }],
  ['path', { d: 'M16 2v4' }],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v2' }],
  ['path', { d: 'M20 12v2' }],
  ['path', { d: 'M20 18v2a2 2 0 0 1-2 2h-1' }],
  ['path', { d: 'M13 22h-2' }],
  ['path', { d: 'M7 22H6a2 2 0 0 1-2-2v-2' }],
  ['path', { d: 'M4 14v-2' }],
  ['path', { d: 'M4 8V6a2 2 0 0 1 2-2h2' }],
  ['path', { d: 'M8 10h6' }],
  ['path', { d: 'M8 14h8' }],
  ['path', { d: 'M8 18h5' }],
];

export default NotepadTextDashed;
