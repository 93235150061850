import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name user-round-search
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMCIgY3k9IjgiIHI9IjUiIC8+CiAgPHBhdGggZD0iTTIgMjFhOCA4IDAgMCAxIDEwLjQzNC03LjYyIiAvPgogIDxjaXJjbGUgY3g9IjE4IiBjeT0iMTgiIHI9IjMiIC8+CiAgPHBhdGggZD0ibTIyIDIyLTEuOS0xLjkiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/user-round-search
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const UserRoundSearch: IconNode = [
  ['circle', { cx: '10', cy: '8', r: '5' }],
  ['path', { d: 'M2 21a8 8 0 0 1 10.434-7.62' }],
  ['circle', { cx: '18', cy: '18', r: '3' }],
  ['path', { d: 'm22 22-1.9-1.9' }],
];

export default UserRoundSearch;
