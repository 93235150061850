import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name text-search
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEgNkgzIiAvPgogIDxwYXRoIGQ9Ik0xMCAxMkgzIiAvPgogIDxwYXRoIGQ9Ik0xMCAxOEgzIiAvPgogIDxjaXJjbGUgY3g9IjE3IiBjeT0iMTUiIHI9IjMiIC8+CiAgPHBhdGggZD0ibTIxIDE5LTEuOS0xLjkiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/text-search
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TextSearch: IconNode = [
  ['path', { d: 'M21 6H3' }],
  ['path', { d: 'M10 12H3' }],
  ['path', { d: 'M10 18H3' }],
  ['circle', { cx: '17', cy: '15', r: '3' }],
  ['path', { d: 'm21 19-1.9-1.9' }],
];

export default TextSearch;
