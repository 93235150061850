import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name alarm-smoke
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMjFjMC0yLjUgMi0yLjUgMi01IiAvPgogIDxwYXRoIGQ9Ik0xNiAyMWMwLTIuNSAyLTIuNSAyLTUiIC8+CiAgPHBhdGggZD0ibTE5IDgtLjggM2ExLjI1IDEuMjUgMCAwIDEtMS4yIDFIN2ExLjI1IDEuMjUgMCAwIDEtMS4yLTFMNSA4IiAvPgogIDxwYXRoIGQ9Ik0yMSAzYTEgMSAwIDAgMSAxIDF2MmEyIDIgMCAwIDEtMiAySDRhMiAyIDAgMCAxLTItMlY0YTEgMSAwIDAgMSAxLTF6IiAvPgogIDxwYXRoIGQ9Ik02IDIxYzAtMi41IDItMi41IDItNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/alarm-smoke
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const AlarmSmoke: IconNode = [
  ['path', { d: 'M11 21c0-2.5 2-2.5 2-5' }],
  ['path', { d: 'M16 21c0-2.5 2-2.5 2-5' }],
  ['path', { d: 'm19 8-.8 3a1.25 1.25 0 0 1-1.2 1H7a1.25 1.25 0 0 1-1.2-1L5 8' }],
  ['path', { d: 'M21 3a1 1 0 0 1 1 1v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a1 1 0 0 1 1-1z' }],
  ['path', { d: 'M6 21c0-2.5 2-2.5 2-5' }],
];

export default AlarmSmoke;
