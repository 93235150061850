import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name arrow-left-right
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOCAzIDQgN2w0IDQiIC8+CiAgPHBhdGggZD0iTTQgN2gxNiIgLz4KICA8cGF0aCBkPSJtMTYgMjEgNC00LTQtNCIgLz4KICA8cGF0aCBkPSJNMjAgMTdINCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/arrow-left-right
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ArrowLeftRight: IconNode = [
  ['path', { d: 'M8 3 4 7l4 4' }],
  ['path', { d: 'M4 7h16' }],
  ['path', { d: 'm16 21 4-4-4-4' }],
  ['path', { d: 'M20 17H4' }],
];

export default ArrowLeftRight;
