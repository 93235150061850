import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name tablets
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSI3IiBjeT0iNyIgcj0iNSIgLz4KICA8Y2lyY2xlIGN4PSIxNyIgY3k9IjE3IiByPSI1IiAvPgogIDxwYXRoIGQ9Ik0xMiAxN2gxMCIgLz4KICA8cGF0aCBkPSJtMy40NiAxMC41NCA3LjA4LTcuMDgiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/tablets
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Tablets: IconNode = [
  ['circle', { cx: '7', cy: '7', r: '5' }],
  ['circle', { cx: '17', cy: '17', r: '5' }],
  ['path', { d: 'M12 17h10' }],
  ['path', { d: 'm3.46 10.54 7.08-7.08' }],
];

export default Tablets;
