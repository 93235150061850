import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name grape
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjIgNVYybC01Ljg5IDUuODkiIC8+CiAgPGNpcmNsZSBjeD0iMTYuNiIgY3k9IjE1Ljg5IiByPSIzIiAvPgogIDxjaXJjbGUgY3g9IjguMTEiIGN5PSI3LjQiIHI9IjMiIC8+CiAgPGNpcmNsZSBjeD0iMTIuMzUiIGN5PSIxMS42NSIgcj0iMyIgLz4KICA8Y2lyY2xlIGN4PSIxMy45MSIgY3k9IjUuODUiIHI9IjMiIC8+CiAgPGNpcmNsZSBjeD0iMTguMTUiIGN5PSIxMC4wOSIgcj0iMyIgLz4KICA8Y2lyY2xlIGN4PSI2LjU2IiBjeT0iMTMuMiIgcj0iMyIgLz4KICA8Y2lyY2xlIGN4PSIxMC44IiBjeT0iMTcuNDQiIHI9IjMiIC8+CiAgPGNpcmNsZSBjeD0iNSIgY3k9IjE5IiByPSIzIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/grape
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Grape: IconNode = [
  ['path', { d: 'M22 5V2l-5.89 5.89' }],
  ['circle', { cx: '16.6', cy: '15.89', r: '3' }],
  ['circle', { cx: '8.11', cy: '7.4', r: '3' }],
  ['circle', { cx: '12.35', cy: '11.65', r: '3' }],
  ['circle', { cx: '13.91', cy: '5.85', r: '3' }],
  ['circle', { cx: '18.15', cy: '10.09', r: '3' }],
  ['circle', { cx: '6.56', cy: '13.2', r: '3' }],
  ['circle', { cx: '10.8', cy: '17.44', r: '3' }],
  ['circle', { cx: '5', cy: '19', r: '3' }],
];

export default Grape;
