import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name keyboard-music
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMTYiIHg9IjIiIHk9IjQiIHJ4PSIyIiAvPgogIDxwYXRoIGQ9Ik02IDhoNCIgLz4KICA8cGF0aCBkPSJNMTQgOGguMDEiIC8+CiAgPHBhdGggZD0iTTE4IDhoLjAxIiAvPgogIDxwYXRoIGQ9Ik0yIDEyaDIwIiAvPgogIDxwYXRoIGQ9Ik02IDEydjQiIC8+CiAgPHBhdGggZD0iTTEwIDEydjQiIC8+CiAgPHBhdGggZD0iTTE0IDEydjQiIC8+CiAgPHBhdGggZD0iTTE4IDEydjQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/keyboard-music
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const KeyboardMusic: IconNode = [
  ['rect', { width: '20', height: '16', x: '2', y: '4', rx: '2' }],
  ['path', { d: 'M6 8h4' }],
  ['path', { d: 'M14 8h.01' }],
  ['path', { d: 'M18 8h.01' }],
  ['path', { d: 'M2 12h20' }],
  ['path', { d: 'M6 12v4' }],
  ['path', { d: 'M10 12v4' }],
  ['path', { d: 'M14 12v4' }],
  ['path', { d: 'M18 12v4' }],
];

export default KeyboardMusic;
