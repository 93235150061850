import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name drill
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMThhMSAxIDAgMCAxIDEgMXYyYTEgMSAwIDAgMS0xIDFINWEzIDMgMCAwIDEtMy0zIDEgMSAwIDAgMSAxLTF6IiAvPgogIDxwYXRoIGQ9Ik0xMyAxMEg0YTIgMiAwIDAgMS0yLTJWNGEyIDIgMCAwIDEgMi0yaDlhMSAxIDAgMCAxIDEgMXY2YTEgMSAwIDAgMS0xIDFsLS44MSAzLjI0MmExIDEgMCAwIDEtLjk3Ljc1OEg4IiAvPgogIDxwYXRoIGQ9Ik0xNCA0aDNhMSAxIDAgMCAxIDEgMXYyYTEgMSAwIDAgMS0xIDFoLTMiIC8+CiAgPHBhdGggZD0iTTE4IDZoNCIgLz4KICA8cGF0aCBkPSJtNSAxMC0yIDgiIC8+CiAgPHBhdGggZD0ibTcgMTggMi04IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/drill
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Drill: IconNode = [
  ['path', { d: 'M10 18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H5a3 3 0 0 1-3-3 1 1 0 0 1 1-1z' }],
  [
    'path',
    {
      d: 'M13 10H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1l-.81 3.242a1 1 0 0 1-.97.758H8',
    },
  ],
  ['path', { d: 'M14 4h3a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-3' }],
  ['path', { d: 'M18 6h4' }],
  ['path', { d: 'm5 10-2 8' }],
  ['path', { d: 'm7 18 2-8' }],
];

export default Drill;
