import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name file-scan
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjAgMTBWN2wtNS01SDZhMiAyIDAgMCAwLTIgMnYxNmEyIDIgMCAwIDAgMiAyaDQiIC8+CiAgPHBhdGggZD0iTTE0IDJ2NGEyIDIgMCAwIDAgMiAyaDQiIC8+CiAgPHBhdGggZD0iTTE2IDE0YTIgMiAwIDAgMC0yIDIiIC8+CiAgPHBhdGggZD0iTTIwIDE0YTIgMiAwIDAgMSAyIDIiIC8+CiAgPHBhdGggZD0iTTIwIDIyYTIgMiAwIDAgMCAyLTIiIC8+CiAgPHBhdGggZD0iTTE2IDIyYTIgMiAwIDAgMS0yLTIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/file-scan
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FileScan: IconNode = [
  ['path', { d: 'M20 10V7l-5-5H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h4' }],
  ['path', { d: 'M14 2v4a2 2 0 0 0 2 2h4' }],
  ['path', { d: 'M16 14a2 2 0 0 0-2 2' }],
  ['path', { d: 'M20 14a2 2 0 0 1 2 2' }],
  ['path', { d: 'M20 22a2 2 0 0 0 2-2' }],
  ['path', { d: 'M16 22a2 2 0 0 1-2-2' }],
];

export default FileScan;
