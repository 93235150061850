import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name accessibility
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxNiIgY3k9IjQiIHI9IjEiIC8+CiAgPHBhdGggZD0ibTE4IDE5IDEtNy02IDEiIC8+CiAgPHBhdGggZD0ibTUgOCAzLTMgNS41IDMtMi4zNiAzLjUiIC8+CiAgPHBhdGggZD0iTTQuMjQgMTQuNWE1IDUgMCAwIDAgNi44OCA2IiAvPgogIDxwYXRoIGQ9Ik0xMy43NiAxNy41YTUgNSAwIDAgMC02Ljg4LTYiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/accessibility
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Accessibility: IconNode = [
  ['circle', { cx: '16', cy: '4', r: '1' }],
  ['path', { d: 'm18 19 1-7-6 1' }],
  ['path', { d: 'm5 8 3-3 5.5 3-2.36 3.5' }],
  ['path', { d: 'M4.24 14.5a5 5 0 0 0 6.88 6' }],
  ['path', { d: 'M13.76 17.5a5 5 0 0 0-6.88-6' }],
];

export default Accessibility;
