import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name goal
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMTNWMmw4IDQtOCA0IiAvPgogIDxwYXRoIGQ9Ik0yMC41NjEgMTAuMjIyYTkgOSAwIDEgMS0xMi41NS01LjI5IiAvPgogIDxwYXRoIGQ9Ik04LjAwMiA5Ljk5N2E1IDUgMCAxIDAgOC45IDIuMDIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/goal
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Goal: IconNode = [
  ['path', { d: 'M12 13V2l8 4-8 4' }],
  ['path', { d: 'M20.561 10.222a9 9 0 1 1-12.55-5.29' }],
  ['path', { d: 'M8.002 9.997a5 5 0 1 0 8.9 2.02' }],
];

export default Goal;
