import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name align-horizontal-space-around
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgeD0iOSIgeT0iNyIgcng9IjIiIC8+CiAgPHBhdGggZD0iTTQgMjJWMiIgLz4KICA8cGF0aCBkPSJNMjAgMjJWMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/align-horizontal-space-around
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const AlignHorizontalSpaceAround: IconNode = [
  ['rect', { width: '6', height: '10', x: '9', y: '7', rx: '2' }],
  ['path', { d: 'M4 22V2' }],
  ['path', { d: 'M20 22V2' }],
];

export default AlignHorizontalSpaceAround;
