import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name shower-head
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtNCA0IDIuNSAyLjUiIC8+CiAgPHBhdGggZD0iTTEzLjUgNi41YTQuOTUgNC45NSAwIDAgMC03IDciIC8+CiAgPHBhdGggZD0iTTE1IDUgNSAxNSIgLz4KICA8cGF0aCBkPSJNMTQgMTd2LjAxIiAvPgogIDxwYXRoIGQ9Ik0xMCAxNnYuMDEiIC8+CiAgPHBhdGggZD0iTTEzIDEzdi4wMSIgLz4KICA8cGF0aCBkPSJNMTYgMTB2LjAxIiAvPgogIDxwYXRoIGQ9Ik0xMSAyMHYuMDEiIC8+CiAgPHBhdGggZD0iTTE3IDE0di4wMSIgLz4KICA8cGF0aCBkPSJNMjAgMTF2LjAxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/shower-head
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ShowerHead: IconNode = [
  ['path', { d: 'm4 4 2.5 2.5' }],
  ['path', { d: 'M13.5 6.5a4.95 4.95 0 0 0-7 7' }],
  ['path', { d: 'M15 5 5 15' }],
  ['path', { d: 'M14 17v.01' }],
  ['path', { d: 'M10 16v.01' }],
  ['path', { d: 'M13 13v.01' }],
  ['path', { d: 'M16 10v.01' }],
  ['path', { d: 'M11 20v.01' }],
  ['path', { d: 'M17 14v.01' }],
  ['path', { d: 'M20 11v.01' }],
];

export default ShowerHead;
