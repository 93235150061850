import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name lamp-floor
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOSAyaDZsMyA3SDZsMy03WiIgLz4KICA8cGF0aCBkPSJNMTIgOXYxMyIgLz4KICA8cGF0aCBkPSJNOSAyMmg2IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/lamp-floor
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const LampFloor: IconNode = [
  ['path', { d: 'M9 2h6l3 7H6l3-7Z' }],
  ['path', { d: 'M12 9v13' }],
  ['path', { d: 'M9 22h6' }],
];

export default LampFloor;
