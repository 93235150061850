import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name rabbit
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTMgMTZhMyAzIDAgMCAxIDIuMjQgNSIgLz4KICA8cGF0aCBkPSJNMTggMTJoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xOCAyMWgtOGE0IDQgMCAwIDEtNC00IDcgNyAwIDAgMSA3LTdoLjJMOS42IDYuNGExIDEgMCAxIDEgMi44LTIuOEwxNS44IDdoLjJjMy4zIDAgNiAyLjcgNiA2djFhMiAyIDAgMCAxLTIgMmgtMWEzIDMgMCAwIDAtMyAzIiAvPgogIDxwYXRoIGQ9Ik0yMCA4LjU0VjRhMiAyIDAgMSAwLTQgMHYzIiAvPgogIDxwYXRoIGQ9Ik03LjYxMiAxMi41MjRhMyAzIDAgMSAwLTEuNiA0LjMiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/rabbit
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Rabbit: IconNode = [
  ['path', { d: 'M13 16a3 3 0 0 1 2.24 5' }],
  ['path', { d: 'M18 12h.01' }],
  [
    'path',
    {
      d: 'M18 21h-8a4 4 0 0 1-4-4 7 7 0 0 1 7-7h.2L9.6 6.4a1 1 0 1 1 2.8-2.8L15.8 7h.2c3.3 0 6 2.7 6 6v1a2 2 0 0 1-2 2h-1a3 3 0 0 0-3 3',
    },
  ],
  ['path', { d: 'M20 8.54V4a2 2 0 1 0-4 0v3' }],
  ['path', { d: 'M7.612 12.524a3 3 0 1 0-1.6 4.3' }],
];

export default Rabbit;
