import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name satellite
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTMgNyA5IDMgNSA3bDQgNCIgLz4KICA8cGF0aCBkPSJtMTcgMTEgNCA0LTQgNC00LTQiIC8+CiAgPHBhdGggZD0ibTggMTIgNCA0IDYtNi00LTRaIiAvPgogIDxwYXRoIGQ9Im0xNiA4IDMtMyIgLz4KICA8cGF0aCBkPSJNOSAyMWE2IDYgMCAwIDAtNi02IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/satellite
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Satellite: IconNode = [
  ['path', { d: 'M13 7 9 3 5 7l4 4' }],
  ['path', { d: 'm17 11 4 4-4 4-4-4' }],
  ['path', { d: 'm8 12 4 4 6-6-4-4Z' }],
  ['path', { d: 'm16 8 3-3' }],
  ['path', { d: 'M9 21a6 6 0 0 0-6-6' }],
];

export default Satellite;
