import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name file-heart
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAyMmgxNGEyIDIgMCAwIDAgMi0yVjdsLTUtNUg2YTIgMiAwIDAgMC0yIDJ2MiIgLz4KICA8cGF0aCBkPSJNMTQgMnY0YTIgMiAwIDAgMCAyIDJoNCIgLz4KICA8cGF0aCBkPSJNMTAuMjkgMTAuN2EyLjQzIDIuNDMgMCAwIDAtMi42Ni0uNTJjLS4yOS4xMi0uNTYuMy0uNzguNTNsLS4zNS4zNC0uMzUtLjM0YTIuNDMgMi40MyAwIDAgMC0yLjY1LS41M2MtLjMuMTItLjU2LjMtLjc5LjUzLS45NS45NC0xIDIuNTMuMiAzLjc0TDYuNSAxOGwzLjYtMy41NWMxLjItMS4yMSAxLjE0LTIuOC4xOS0zLjc0WiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/file-heart
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FileHeart: IconNode = [
  ['path', { d: 'M4 22h14a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v2' }],
  ['path', { d: 'M14 2v4a2 2 0 0 0 2 2h4' }],
  [
    'path',
    {
      d: 'M10.29 10.7a2.43 2.43 0 0 0-2.66-.52c-.29.12-.56.3-.78.53l-.35.34-.35-.34a2.43 2.43 0 0 0-2.65-.53c-.3.12-.56.3-.79.53-.95.94-1 2.53.2 3.74L6.5 18l3.6-3.55c1.2-1.21 1.14-2.8.19-3.74Z',
    },
  ],
];

export default FileHeart;
