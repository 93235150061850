import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name file-cog
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQgMnY0YTIgMiAwIDAgMCAyIDJoNCIgLz4KICA8cGF0aCBkPSJtMy4yIDEyLjktLjktLjQiIC8+CiAgPHBhdGggZD0ibTMuMiAxNS4xLS45LjQiIC8+CiAgPHBhdGggZD0iTTQuNjc3IDIxLjVhMiAyIDAgMCAwIDEuMzEzLjVIMThhMiAyIDAgMCAwIDItMlY3bC01LTVINmEyIDIgMCAwIDAtMiAydjIuNSIgLz4KICA8cGF0aCBkPSJtNC45IDExLjItLjQtLjkiIC8+CiAgPHBhdGggZD0ibTQuOSAxNi44LS40LjkiIC8+CiAgPHBhdGggZD0ibTcuNSAxMC4zLS40LjkiIC8+CiAgPHBhdGggZD0ibTcuNSAxNy43LS40LS45IiAvPgogIDxwYXRoIGQ9Im05LjcgMTIuNS0uOS40IiAvPgogIDxwYXRoIGQ9Im05LjcgMTUuNS0uOS0uNCIgLz4KICA8Y2lyY2xlIGN4PSI2IiBjeT0iMTQiIHI9IjMiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/file-cog
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FileCog: IconNode = [
  ['path', { d: 'M14 2v4a2 2 0 0 0 2 2h4' }],
  ['path', { d: 'm3.2 12.9-.9-.4' }],
  ['path', { d: 'm3.2 15.1-.9.4' }],
  ['path', { d: 'M4.677 21.5a2 2 0 0 0 1.313.5H18a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v2.5' }],
  ['path', { d: 'm4.9 11.2-.4-.9' }],
  ['path', { d: 'm4.9 16.8-.4.9' }],
  ['path', { d: 'm7.5 10.3-.4.9' }],
  ['path', { d: 'm7.5 17.7-.4-.9' }],
  ['path', { d: 'm9.7 12.5-.9.4' }],
  ['path', { d: 'm9.7 15.5-.9-.4' }],
  ['circle', { cx: '6', cy: '14', r: '3' }],
];

export default FileCog;
