import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name list-filter-plus
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMThoNCIgLz4KICA8cGF0aCBkPSJNMTEgNkgzIiAvPgogIDxwYXRoIGQ9Ik0xNSA2aDYiIC8+CiAgPHBhdGggZD0iTTE4IDlWMyIgLz4KICA8cGF0aCBkPSJNNyAxMmg4IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/list-filter-plus
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ListFilterPlus: IconNode = [
  ['path', { d: 'M10 18h4' }],
  ['path', { d: 'M11 6H3' }],
  ['path', { d: 'M15 6h6' }],
  ['path', { d: 'M18 9V3' }],
  ['path', { d: 'M7 12h8' }],
];

export default ListFilterPlus;
