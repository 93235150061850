import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name saudi-riyal
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMjAgMTkuNS01LjUgMS4yIiAvPgogIDxwYXRoIGQ9Ik0xNC41IDR2MTEuMjJhMSAxIDAgMCAwIDEuMjQyLjk3TDIwIDE1LjIiIC8+CiAgPHBhdGggZD0ibTIuOTc4IDE5LjM1MSA1LjU0OS0xLjM2M0EyIDIgMCAwIDAgMTAgMTZWMiIgLz4KICA8cGF0aCBkPSJNMjAgMTAgNCAxMy41IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/saudi-riyal
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const SaudiRiyal: IconNode = [
  ['path', { d: 'm20 19.5-5.5 1.2' }],
  ['path', { d: 'M14.5 4v11.22a1 1 0 0 0 1.242.97L20 15.2' }],
  ['path', { d: 'm2.978 19.351 5.549-1.363A2 2 0 0 0 10 16V2' }],
  ['path', { d: 'M20 10 4 13.5' }],
];

export default SaudiRiyal;
