import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name bed
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiA0djE2IiAvPgogIDxwYXRoIGQ9Ik0yIDhoMThhMiAyIDAgMCAxIDIgMnYxMCIgLz4KICA8cGF0aCBkPSJNMiAxN2gyMCIgLz4KICA8cGF0aCBkPSJNNiA4djkiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/bed
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Bed: IconNode = [
  ['path', { d: 'M2 4v16' }],
  ['path', { d: 'M2 8h18a2 2 0 0 1 2 2v10' }],
  ['path', { d: 'M2 17h20' }],
  ['path', { d: 'M6 8v9' }],
];

export default Bed;
