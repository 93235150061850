import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name roller-coaster
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNiAxOVY1IiAvPgogIDxwYXRoIGQ9Ik0xMCAxOVY2LjgiIC8+CiAgPHBhdGggZD0iTTE0IDE5di03LjgiIC8+CiAgPHBhdGggZD0iTTE4IDV2NCIgLz4KICA8cGF0aCBkPSJNMTggMTl2LTYiIC8+CiAgPHBhdGggZD0iTTIyIDE5VjkiIC8+CiAgPHBhdGggZD0iTTIgMTlWOWE0IDQgMCAwIDEgNC00YzIgMCA0IDEuMzMgNiA0czQgNCA2IDRhNCA0IDAgMSAwLTMtNi42NSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/roller-coaster
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const RollerCoaster: IconNode = [
  ['path', { d: 'M6 19V5' }],
  ['path', { d: 'M10 19V6.8' }],
  ['path', { d: 'M14 19v-7.8' }],
  ['path', { d: 'M18 5v4' }],
  ['path', { d: 'M18 19v-6' }],
  ['path', { d: 'M22 19V9' }],
  ['path', { d: 'M2 19V9a4 4 0 0 1 4-4c2 0 4 1.33 6 4s4 4 6 4a4 4 0 1 0-3-6.65' }],
];

export default RollerCoaster;
