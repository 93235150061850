import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name pencil-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTAgMTAtNi4xNTcgNi4xNjJhMiAyIDAgMCAwLS41LjgzM2wtMS4zMjIgNC4zNmEuNS41IDAgMCAwIC42MjIuNjI0bDQuMzU4LTEuMzIzYTIgMiAwIDAgMCAuODMtLjVMMTQgMTMuOTgyIiAvPgogIDxwYXRoIGQ9Im0xMi44MjkgNy4xNzIgNC4zNTktNC4zNDZhMSAxIDAgMSAxIDMuOTg2IDMuOTg2bC00LjM1MyA0LjM1MyIgLz4KICA8cGF0aCBkPSJtMTUgNSA0IDQiIC8+CiAgPHBhdGggZD0ibTIgMiAyMCAyMCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/pencil-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const PencilOff: IconNode = [
  [
    'path',
    {
      d: 'm10 10-6.157 6.162a2 2 0 0 0-.5.833l-1.322 4.36a.5.5 0 0 0 .622.624l4.358-1.323a2 2 0 0 0 .83-.5L14 13.982',
    },
  ],
  ['path', { d: 'm12.829 7.172 4.359-4.346a1 1 0 1 1 3.986 3.986l-4.353 4.353' }],
  ['path', { d: 'm15 5 4 4' }],
  ['path', { d: 'm2 2 20 20' }],
];

export default PencilOff;
