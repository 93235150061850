import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name volume-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYgOWE1IDUgMCAwIDEgLjk1IDIuMjkzIiAvPgogIDxwYXRoIGQ9Ik0xOS4zNjQgNS42MzZhOSA5IDAgMCAxIDEuODg5IDkuOTYiIC8+CiAgPHBhdGggZD0ibTIgMiAyMCAyMCIgLz4KICA8cGF0aCBkPSJtNyA3LS41ODcuNTg3QTEuNCAxLjQgMCAwIDEgNS40MTYgOEgzYTEgMSAwIDAgMC0xIDF2NmExIDEgMCAwIDAgMSAxaDIuNDE2YTEuNCAxLjQgMCAwIDEgLjk5Ny40MTNsMy4zODMgMy4zODRBLjcwNS43MDUgMCAwIDAgMTEgMTkuMjk4VjExIiAvPgogIDxwYXRoIGQ9Ik05LjgyOCA0LjE3MkEuNjg2LjY4NiAwIDAgMSAxMSA0LjY1N3YuNjg2IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/volume-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const VolumeOff: IconNode = [
  ['path', { d: 'M16 9a5 5 0 0 1 .95 2.293' }],
  ['path', { d: 'M19.364 5.636a9 9 0 0 1 1.889 9.96' }],
  ['path', { d: 'm2 2 20 20' }],
  [
    'path',
    {
      d: 'm7 7-.587.587A1.4 1.4 0 0 1 5.416 8H3a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2.416a1.4 1.4 0 0 1 .997.413l3.383 3.384A.705.705 0 0 0 11 19.298V11',
    },
  ],
  ['path', { d: 'M9.828 4.172A.686.686 0 0 1 11 4.657v.686' }],
];

export default VolumeOff;
