import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name router
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iOCIgeD0iMiIgeT0iMTQiIHJ4PSIyIiAvPgogIDxwYXRoIGQ9Ik02LjAxIDE4SDYiIC8+CiAgPHBhdGggZD0iTTEwLjAxIDE4SDEwIiAvPgogIDxwYXRoIGQ9Ik0xNSAxMHY0IiAvPgogIDxwYXRoIGQ9Ik0xNy44NCA3LjE3YTQgNCAwIDAgMC01LjY2IDAiIC8+CiAgPHBhdGggZD0iTTIwLjY2IDQuMzRhOCA4IDAgMCAwLTExLjMxIDAiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/router
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Router: IconNode = [
  ['rect', { width: '20', height: '8', x: '2', y: '14', rx: '2' }],
  ['path', { d: 'M6.01 18H6' }],
  ['path', { d: 'M10.01 18H10' }],
  ['path', { d: 'M15 10v4' }],
  ['path', { d: 'M17.84 7.17a4 4 0 0 0-5.66 0' }],
  ['path', { d: 'M20.66 4.34a8 8 0 0 0-11.31 0' }],
];

export default Router;
