import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name indent-decrease
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEgMTJIMTEiIC8+CiAgPHBhdGggZD0iTTIxIDE4SDExIiAvPgogIDxwYXRoIGQ9Ik0yMSA2SDExIiAvPgogIDxwYXRoIGQ9Im03IDgtNCA0IDQgNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/indent-decrease
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const IndentDecrease: IconNode = [
  ['path', { d: 'M21 12H11' }],
  ['path', { d: 'M21 18H11' }],
  ['path', { d: 'M21 6H11' }],
  ['path', { d: 'm7 8-4 4 4 4' }],
];

export default IndentDecrease;
