import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name candy
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgN3YxMC45IiAvPgogIDxwYXRoIGQ9Ik0xNCA2LjFWMTciIC8+CiAgPHBhdGggZD0iTTE2IDdWM2ExIDEgMCAwIDEgMS43MDctLjcwNyAyLjUgMi41IDAgMCAwIDIuMTUyLjcxNyAxIDEgMCAwIDEgMS4xMzEgMS4xMzEgMi41IDIuNSAwIDAgMCAuNzE3IDIuMTUyQTEgMSAwIDAgMSAyMSA4aC00IiAvPgogIDxwYXRoIGQ9Ik0xNi41MzYgNy40NjVhNSA1IDAgMCAwLTcuMDcyIDBsLTIgMmE1IDUgMCAwIDAgMCA3LjA3IDUgNSAwIDAgMCA3LjA3MiAwbDItMmE1IDUgMCAwIDAgMC03LjA3IiAvPgogIDxwYXRoIGQ9Ik04IDE3djRhMSAxIDAgMCAxLTEuNzA3LjcwNyAyLjUgMi41IDAgMCAwLTIuMTUyLS43MTcgMSAxIDAgMCAxLTEuMTMxLTEuMTMxIDIuNSAyLjUgMCAwIDAtLjcxNy0yLjE1MkExIDEgMCAwIDEgMyAxNmg0IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/candy
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Candy: IconNode = [
  ['path', { d: 'M10 7v10.9' }],
  ['path', { d: 'M14 6.1V17' }],
  [
    'path',
    {
      d: 'M16 7V3a1 1 0 0 1 1.707-.707 2.5 2.5 0 0 0 2.152.717 1 1 0 0 1 1.131 1.131 2.5 2.5 0 0 0 .717 2.152A1 1 0 0 1 21 8h-4',
    },
  ],
  [
    'path',
    {
      d: 'M16.536 7.465a5 5 0 0 0-7.072 0l-2 2a5 5 0 0 0 0 7.07 5 5 0 0 0 7.072 0l2-2a5 5 0 0 0 0-7.07',
    },
  ],
  [
    'path',
    {
      d: 'M8 17v4a1 1 0 0 1-1.707.707 2.5 2.5 0 0 0-2.152-.717 1 1 0 0 1-1.131-1.131 2.5 2.5 0 0 0-.717-2.152A1 1 0 0 1 3 16h4',
    },
  ],
];

export default Candy;
