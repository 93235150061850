import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name arrow-down-left
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTcgNyA3IDE3IiAvPgogIDxwYXRoIGQ9Ik0xNyAxN0g3VjciIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/arrow-down-left
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ArrowDownLeft: IconNode = [
  ['path', { d: 'M17 7 7 17' }],
  ['path', { d: 'M17 17H7V7' }],
];

export default ArrowDownLeft;
