import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cone
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMjAuOSAxOC41NS04LTE1Ljk4YTEgMSAwIDAgMC0xLjggMGwtOCAxNS45OCIgLz4KICA8ZWxsaXBzZSBjeD0iMTIiIGN5PSIxOSIgcng9IjkiIHJ5PSIzIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/cone
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Cone: IconNode = [
  ['path', { d: 'm20.9 18.55-8-15.98a1 1 0 0 0-1.8 0l-8 15.98' }],
  ['ellipse', { cx: '12', cy: '19', rx: '9', ry: '3' }],
];

export default Cone;
