import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name arrow-down-to-dot
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMnYxNCIgLz4KICA8cGF0aCBkPSJtMTkgOS03IDctNy03IiAvPgogIDxjaXJjbGUgY3g9IjEyIiBjeT0iMjEiIHI9IjEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/arrow-down-to-dot
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ArrowDownToDot: IconNode = [
  ['path', { d: 'M12 2v14' }],
  ['path', { d: 'm19 9-7 7-7-7' }],
  ['circle', { cx: '12', cy: '21', r: '1' }],
];

export default ArrowDownToDot;
