import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name ear-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNiAxOC41YTMuNSAzLjUgMCAxIDAgNyAwYzAtMS41Ny45Mi0yLjUyIDIuMDQtMy40NiIgLz4KICA8cGF0aCBkPSJNNiA4LjVjMC0uNzUuMTMtMS40Ny4zNi0yLjE0IiAvPgogIDxwYXRoIGQ9Ik04LjggMy4xNUE2LjUgNi41IDAgMCAxIDE5IDguNWMwIDEuNjMtLjQ0IDIuODEtMS4wOSAzLjc2IiAvPgogIDxwYXRoIGQ9Ik0xMi41IDZBMi41IDIuNSAwIDAgMSAxNSA4LjVNMTAgMTNhMiAyIDAgMCAwIDEuODItMS4xOCIgLz4KICA8bGluZSB4MT0iMiIgeDI9IjIyIiB5MT0iMiIgeTI9IjIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/ear-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const EarOff: IconNode = [
  ['path', { d: 'M6 18.5a3.5 3.5 0 1 0 7 0c0-1.57.92-2.52 2.04-3.46' }],
  ['path', { d: 'M6 8.5c0-.75.13-1.47.36-2.14' }],
  ['path', { d: 'M8.8 3.15A6.5 6.5 0 0 1 19 8.5c0 1.63-.44 2.81-1.09 3.76' }],
  ['path', { d: 'M12.5 6A2.5 2.5 0 0 1 15 8.5M10 13a2 2 0 0 0 1.82-1.18' }],
  ['line', { x1: '2', x2: '22', y1: '2', y2: '22' }],
];

export default EarOff;
