import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name move-3d
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNSAzdjE2aDE2IiAvPgogIDxwYXRoIGQ9Im01IDE5IDYtNiIgLz4KICA8cGF0aCBkPSJtMiA2IDMtMyAzIDMiIC8+CiAgPHBhdGggZD0ibTE4IDE2IDMgMy0zIDMiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/move-3d
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Move3d: IconNode = [
  ['path', { d: 'M5 3v16h16' }],
  ['path', { d: 'm5 19 6-6' }],
  ['path', { d: 'm2 6 3-3 3 3' }],
  ['path', { d: 'm18 16 3 3-3 3' }],
];

export default Move3d;
