import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name brackets
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYgM2gyYTEgMSAwIDAgMSAxIDF2MTZhMSAxIDAgMCAxLTEgMWgtMiIgLz4KICA8cGF0aCBkPSJNOCAyMUg2YTEgMSAwIDAgMS0xLTFWNGExIDEgMCAwIDEgMS0xaDIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/brackets
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Brackets: IconNode = [
  ['path', { d: 'M16 3h2a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-2' }],
  ['path', { d: 'M8 21H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2' }],
];

export default Brackets;
