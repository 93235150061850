import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name git-branch-plus
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNiAzdjEyIiAvPgogIDxwYXRoIGQ9Ik0xOCA5YTMgMyAwIDEgMCAwLTYgMyAzIDAgMCAwIDAgNnoiIC8+CiAgPHBhdGggZD0iTTYgMjFhMyAzIDAgMSAwIDAtNiAzIDMgMCAwIDAgMCA2eiIgLz4KICA8cGF0aCBkPSJNMTUgNmE5IDkgMCAwIDAtOSA5IiAvPgogIDxwYXRoIGQ9Ik0xOCAxNXY2IiAvPgogIDxwYXRoIGQ9Ik0yMSAxOGgtNiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/git-branch-plus
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const GitBranchPlus: IconNode = [
  ['path', { d: 'M6 3v12' }],
  ['path', { d: 'M18 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' }],
  ['path', { d: 'M6 21a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' }],
  ['path', { d: 'M15 6a9 9 0 0 0-9 9' }],
  ['path', { d: 'M18 15v6' }],
  ['path', { d: 'M21 18h-6' }],
];

export default GitBranchPlus;
