import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name briefcase-conveyor-belt
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMjB2MiIgLz4KICA8cGF0aCBkPSJNMTQgMjB2MiIgLz4KICA8cGF0aCBkPSJNMTggMjB2MiIgLz4KICA8cGF0aCBkPSJNMjEgMjBIMyIgLz4KICA8cGF0aCBkPSJNNiAyMHYyIiAvPgogIDxwYXRoIGQ9Ik04IDE2VjRhMiAyIDAgMCAxIDItMmg0YTIgMiAwIDAgMSAyIDJ2MTIiIC8+CiAgPHJlY3QgeD0iNCIgeT0iNiIgd2lkdGg9IjE2IiBoZWlnaHQ9IjEwIiByeD0iMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/briefcase-conveyor-belt
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BriefcaseConveyorBelt: IconNode = [
  ['path', { d: 'M10 20v2' }],
  ['path', { d: 'M14 20v2' }],
  ['path', { d: 'M18 20v2' }],
  ['path', { d: 'M21 20H3' }],
  ['path', { d: 'M6 20v2' }],
  ['path', { d: 'M8 16V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v12' }],
  ['rect', { x: '4', y: '6', width: '16', height: '10', rx: '2' }],
];

export default BriefcaseConveyorBelt;
