import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name reply-all
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cG9seWxpbmUgcG9pbnRzPSI3IDE3IDIgMTIgNyA3IiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjEyIDE3IDcgMTIgMTIgNyIgLz4KICA8cGF0aCBkPSJNMjIgMTh2LTJhNCA0IDAgMCAwLTQtNEg3IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/reply-all
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ReplyAll: IconNode = [
  ['polyline', { points: '7 17 2 12 7 7' }],
  ['polyline', { points: '12 17 7 12 12 7' }],
  ['path', { d: 'M22 18v-2a4 4 0 0 0-4-4H7' }],
];

export default ReplyAll;
