import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name heading-3
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAxMmg4IiAvPgogIDxwYXRoIGQ9Ik00IDE4VjYiIC8+CiAgPHBhdGggZD0iTTEyIDE4VjYiIC8+CiAgPHBhdGggZD0iTTE3LjUgMTAuNWMxLjctMSAzLjUgMCAzLjUgMS41YTIgMiAwIDAgMS0yIDIiIC8+CiAgPHBhdGggZD0iTTE3IDE3LjVjMiAxLjUgNCAuMyA0LTEuNWEyIDIgMCAwIDAtMi0yIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/heading-3
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Heading3: IconNode = [
  ['path', { d: 'M4 12h8' }],
  ['path', { d: 'M4 18V6' }],
  ['path', { d: 'M12 18V6' }],
  ['path', { d: 'M17.5 10.5c1.7-1 3.5 0 3.5 1.5a2 2 0 0 1-2 2' }],
  ['path', { d: 'M17 17.5c2 1.5 4 .3 4-1.5a2 2 0 0 0-2-2' }],
];

export default Heading3;
