import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name list-end
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYgMTJIMyIgLz4KICA8cGF0aCBkPSJNMTYgNkgzIiAvPgogIDxwYXRoIGQ9Ik0xMCAxOEgzIiAvPgogIDxwYXRoIGQ9Ik0yMSA2djEwYTIgMiAwIDAgMS0yIDJoLTUiIC8+CiAgPHBhdGggZD0ibTE2IDE2LTIgMiAyIDIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/list-end
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ListEnd: IconNode = [
  ['path', { d: 'M16 12H3' }],
  ['path', { d: 'M16 6H3' }],
  ['path', { d: 'M10 18H3' }],
  ['path', { d: 'M21 6v10a2 2 0 0 1-2 2h-5' }],
  ['path', { d: 'm16 16-2 2 2 2' }],
];

export default ListEnd;
