import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name clock-alert
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgNnY2bDQgMiIgLz4KICA8cGF0aCBkPSJNMTYgMjEuMTZhMTAgMTAgMCAxIDEgNS0xMy41MTYiIC8+CiAgPHBhdGggZD0iTTIwIDExLjV2NiIgLz4KICA8cGF0aCBkPSJNMjAgMjEuNWguMDEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/clock-alert
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ClockAlert: IconNode = [
  ['path', { d: 'M12 6v6l4 2' }],
  ['path', { d: 'M16 21.16a10 10 0 1 1 5-13.516' }],
  ['path', { d: 'M20 11.5v6' }],
  ['path', { d: 'M20 21.5h.01' }],
];

export default ClockAlert;
