import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name picture-in-picture-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEgOVY2YTIgMiAwIDAgMC0yLTJINGEyIDIgMCAwIDAtMiAydjEwYzAgMS4xLjkgMiAyIDJoNCIgLz4KICA8cmVjdCB3aWR0aD0iMTAiIGhlaWdodD0iNyIgeD0iMTIiIHk9IjEzIiByeD0iMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/picture-in-picture-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const PictureInPicture2: IconNode = [
  ['path', { d: 'M21 9V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h4' }],
  ['rect', { width: '10', height: '7', x: '12', y: '13', rx: '2' }],
];

export default PictureInPicture2;
