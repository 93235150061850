import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name bug-play
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIuNzY1IDIxLjUyMmEuNS41IDAgMCAxLS43NjUtLjQyNHYtOC4xOTZhLjUuNSAwIDAgMSAuNzY1LS40MjRsNS44NzggMy42NzRhMSAxIDAgMCAxIDAgMS42OTZ6IiAvPgogIDxwYXRoIGQ9Ik0xNC4xMiAzLjg4IDE2IDIiIC8+CiAgPHBhdGggZD0iTTE4IDExYTQgNCAwIDAgMC00LTRoLTRhNCA0IDAgMCAwLTQgNHYzYTYuMSA2LjEgMCAwIDAgMiA0LjUiIC8+CiAgPHBhdGggZD0iTTIwLjk3IDVjMCAyLjEtMS42IDMuOC0zLjUgNCIgLz4KICA8cGF0aCBkPSJNMyAyMWMwLTIuMSAxLjctMy45IDMuOC00IiAvPgogIDxwYXRoIGQ9Ik02IDEzSDIiIC8+CiAgPHBhdGggZD0iTTYuNTMgOUM0LjYgOC44IDMgNy4xIDMgNSIgLz4KICA8cGF0aCBkPSJtOCAyIDEuODggMS44OCIgLz4KICA8cGF0aCBkPSJNOSA3LjEzdi0xYTMuMDAzIDMuMDAzIDAgMSAxIDYgMHYxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/bug-play
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BugPlay: IconNode = [
  [
    'path',
    {
      d: 'M12.765 21.522a.5.5 0 0 1-.765-.424v-8.196a.5.5 0 0 1 .765-.424l5.878 3.674a1 1 0 0 1 0 1.696z',
    },
  ],
  ['path', { d: 'M14.12 3.88 16 2' }],
  ['path', { d: 'M18 11a4 4 0 0 0-4-4h-4a4 4 0 0 0-4 4v3a6.1 6.1 0 0 0 2 4.5' }],
  ['path', { d: 'M20.97 5c0 2.1-1.6 3.8-3.5 4' }],
  ['path', { d: 'M3 21c0-2.1 1.7-3.9 3.8-4' }],
  ['path', { d: 'M6 13H2' }],
  ['path', { d: 'M6.53 9C4.6 8.8 3 7.1 3 5' }],
  ['path', { d: 'm8 2 1.88 1.88' }],
  ['path', { d: 'M9 7.13v-1a3.003 3.003 0 1 1 6 0v1' }],
];

export default BugPlay;
