import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name popsicle
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTguNiAxNC40Yy44LS44LjgtMiAwLTIuOGwtOC4xLTguMWE0Ljk1IDQuOTUgMCAxIDAtNy4xIDcuMWw4LjEgOC4xYy45LjcgMi4xLjcgMi45LS4xWiIgLz4KICA8cGF0aCBkPSJtMjIgMjItNS41LTUuNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/popsicle
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Popsicle: IconNode = [
  [
    'path',
    { d: 'M18.6 14.4c.8-.8.8-2 0-2.8l-8.1-8.1a4.95 4.95 0 1 0-7.1 7.1l8.1 8.1c.9.7 2.1.7 2.9-.1Z' },
  ],
  ['path', { d: 'm22 22-5.5-5.5' }],
];

export default Popsicle;
