import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name orbit
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIzIiAvPgogIDxjaXJjbGUgY3g9IjE5IiBjeT0iNSIgcj0iMiIgLz4KICA8Y2lyY2xlIGN4PSI1IiBjeT0iMTkiIHI9IjIiIC8+CiAgPHBhdGggZD0iTTEwLjQgMjEuOWExMCAxMCAwIDAgMCA5Ljk0MS0xNS40MTYiIC8+CiAgPHBhdGggZD0iTTEzLjUgMi4xYTEwIDEwIDAgMCAwLTkuODQxIDE1LjQxNiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/orbit
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Orbit: IconNode = [
  ['circle', { cx: '12', cy: '12', r: '3' }],
  ['circle', { cx: '19', cy: '5', r: '2' }],
  ['circle', { cx: '5', cy: '19', r: '2' }],
  ['path', { d: 'M10.4 21.9a10 10 0 0 0 9.941-15.416' }],
  ['path', { d: 'M13.5 2.1a10 10 0 0 0-9.841 15.416' }],
];

export default Orbit;
