import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name sailboat
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjIgMThIMmE0IDQgMCAwIDAgNCA0aDEyYTQgNCAwIDAgMCA0LTRaIiAvPgogIDxwYXRoIGQ9Ik0yMSAxNCAxMCAyIDMgMTRoMThaIiAvPgogIDxwYXRoIGQ9Ik0xMCAydjE2IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/sailboat
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Sailboat: IconNode = [
  ['path', { d: 'M22 18H2a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4Z' }],
  ['path', { d: 'M21 14 10 2 3 14h18Z' }],
  ['path', { d: 'M10 2v16' }],
];

export default Sailboat;
