import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name tent-tree
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSI0IiBjeT0iNCIgcj0iMiIgLz4KICA8cGF0aCBkPSJtMTQgNSAzLTMgMyAzIiAvPgogIDxwYXRoIGQ9Im0xNCAxMCAzLTMgMyAzIiAvPgogIDxwYXRoIGQ9Ik0xNyAxNFYyIiAvPgogIDxwYXRoIGQ9Ik0xNyAxNEg3bC01IDhoMjBaIiAvPgogIDxwYXRoIGQ9Ik04IDE0djgiIC8+CiAgPHBhdGggZD0ibTkgMTQgNSA4IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/tent-tree
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TentTree: IconNode = [
  ['circle', { cx: '4', cy: '4', r: '2' }],
  ['path', { d: 'm14 5 3-3 3 3' }],
  ['path', { d: 'm14 10 3-3 3 3' }],
  ['path', { d: 'M17 14V2' }],
  ['path', { d: 'M17 14H7l-5 8h20Z' }],
  ['path', { d: 'M8 14v8' }],
  ['path', { d: 'm9 14 5 8' }],
];

export default TentTree;
