import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name medal
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNy4yMSAxNSAyLjY2IDcuMTRhMiAyIDAgMCAxIC4xMy0yLjJMNC40IDIuOEEyIDIgMCAwIDEgNiAyaDEyYTIgMiAwIDAgMSAxLjYuOGwxLjYgMi4xNGEyIDIgMCAwIDEgLjE0IDIuMkwxNi43OSAxNSIgLz4KICA8cGF0aCBkPSJNMTEgMTIgNS4xMiAyLjIiIC8+CiAgPHBhdGggZD0ibTEzIDEyIDUuODgtOS44IiAvPgogIDxwYXRoIGQ9Ik04IDdoOCIgLz4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjE3IiByPSI1IiAvPgogIDxwYXRoIGQ9Ik0xMiAxOHYtMmgtLjUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/medal
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Medal: IconNode = [
  [
    'path',
    {
      d: 'M7.21 15 2.66 7.14a2 2 0 0 1 .13-2.2L4.4 2.8A2 2 0 0 1 6 2h12a2 2 0 0 1 1.6.8l1.6 2.14a2 2 0 0 1 .14 2.2L16.79 15',
    },
  ],
  ['path', { d: 'M11 12 5.12 2.2' }],
  ['path', { d: 'm13 12 5.88-9.8' }],
  ['path', { d: 'M8 7h8' }],
  ['circle', { cx: '12', cy: '17', r: '5' }],
  ['path', { d: 'M12 18v-2h-.5' }],
];

export default Medal;
