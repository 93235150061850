import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name keyboard-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNIDIwIDQgQTIgMiAwIDAgMSAyMiA2IiAvPgogIDxwYXRoIGQ9Ik0gMjIgNiBMIDIyIDE2LjQxIiAvPgogIDxwYXRoIGQ9Ik0gNyAxNiBMIDE2IDE2IiAvPgogIDxwYXRoIGQ9Ik0gOS42OSA0IEwgMjAgNCIgLz4KICA8cGF0aCBkPSJNMTQgOGguMDEiIC8+CiAgPHBhdGggZD0iTTE4IDhoLjAxIiAvPgogIDxwYXRoIGQ9Im0yIDIgMjAgMjAiIC8+CiAgPHBhdGggZD0iTTIwIDIwSDRhMiAyIDAgMCAxLTItMlY2YTIgMiAwIDAgMSAyLTIiIC8+CiAgPHBhdGggZD0iTTYgOGguMDEiIC8+CiAgPHBhdGggZD0iTTggMTJoLjAxIiAvPgo8L3N2Zz4=) - https://lucide.dev/icons/keyboard-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const KeyboardOff: IconNode = [
  ['path', { d: 'M 20 4 A2 2 0 0 1 22 6' }],
  ['path', { d: 'M 22 6 L 22 16.41' }],
  ['path', { d: 'M 7 16 L 16 16' }],
  ['path', { d: 'M 9.69 4 L 20 4' }],
  ['path', { d: 'M14 8h.01' }],
  ['path', { d: 'M18 8h.01' }],
  ['path', { d: 'm2 2 20 20' }],
  ['path', { d: 'M20 20H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2' }],
  ['path', { d: 'M6 8h.01' }],
  ['path', { d: 'M8 12h.01' }],
];

export default KeyboardOff;
