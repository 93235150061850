import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name ship-wheel
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSI4IiAvPgogIDxwYXRoIGQ9Ik0xMiAydjcuNSIgLz4KICA8cGF0aCBkPSJtMTkgNS01LjIzIDUuMjMiIC8+CiAgPHBhdGggZD0iTTIyIDEyaC03LjUiIC8+CiAgPHBhdGggZD0ibTE5IDE5LTUuMjMtNS4yMyIgLz4KICA8cGF0aCBkPSJNMTIgMTQuNVYyMiIgLz4KICA8cGF0aCBkPSJNMTAuMjMgMTMuNzcgNSAxOSIgLz4KICA8cGF0aCBkPSJNOS41IDEySDIiIC8+CiAgPHBhdGggZD0iTTEwLjIzIDEwLjIzIDUgNSIgLz4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIyLjUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/ship-wheel
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ShipWheel: IconNode = [
  ['circle', { cx: '12', cy: '12', r: '8' }],
  ['path', { d: 'M12 2v7.5' }],
  ['path', { d: 'm19 5-5.23 5.23' }],
  ['path', { d: 'M22 12h-7.5' }],
  ['path', { d: 'm19 19-5.23-5.23' }],
  ['path', { d: 'M12 14.5V22' }],
  ['path', { d: 'M10.23 13.77 5 19' }],
  ['path', { d: 'M9.5 12H2' }],
  ['path', { d: 'M10.23 10.23 5 5' }],
  ['circle', { cx: '12', cy: '12', r: '2.5' }],
];

export default ShipWheel;
