import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name between-horizontal-start
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMTMiIGhlaWdodD0iNyIgeD0iOCIgeT0iMyIgcng9IjEiIC8+CiAgPHBhdGggZD0ibTIgOSAzIDMtMyAzIiAvPgogIDxyZWN0IHdpZHRoPSIxMyIgaGVpZ2h0PSI3IiB4PSI4IiB5PSIxNCIgcng9IjEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/between-horizontal-start
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BetweenHorizontalStart: IconNode = [
  ['rect', { width: '13', height: '7', x: '8', y: '3', rx: '1' }],
  ['path', { d: 'm2 9 3 3-3 3' }],
  ['rect', { width: '13', height: '7', x: '8', y: '14', rx: '1' }],
];

export default BetweenHorizontalStart;
