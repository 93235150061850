import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name mountain-snow
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtOCAzIDQgOCA1LTUgNSAxNUgyTDggM3oiIC8+CiAgPHBhdGggZD0iTTQuMTQgMTUuMDhjMi42Mi0xLjU3IDUuMjQtMS40MyA3Ljg2LjQyIDIuNzQgMS45NCA1LjQ5IDIgOC4yMy4xOSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/mountain-snow
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MountainSnow: IconNode = [
  ['path', { d: 'm8 3 4 8 5-5 5 15H2L8 3z' }],
  ['path', { d: 'M4.14 15.08c2.62-1.57 5.24-1.43 7.86.42 2.74 1.94 5.49 2 8.23.19' }],
];

export default MountainSnow;
