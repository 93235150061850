import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name unplug
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTkgNSAzLTMiIC8+CiAgPHBhdGggZD0ibTIgMjIgMy0zIiAvPgogIDxwYXRoIGQ9Ik02LjMgMjAuM2EyLjQgMi40IDAgMCAwIDMuNCAwTDEyIDE4bC02LTYtMi4zIDIuM2EyLjQgMi40IDAgMCAwIDAgMy40WiIgLz4KICA8cGF0aCBkPSJNNy41IDEzLjUgMTAgMTEiIC8+CiAgPHBhdGggZD0iTTEwLjUgMTYuNSAxMyAxNCIgLz4KICA8cGF0aCBkPSJtMTIgNiA2IDYgMi4zLTIuM2EyLjQgMi40IDAgMCAwIDAtMy40bC0yLjYtMi42YTIuNCAyLjQgMCAwIDAtMy40IDBaIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/unplug
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Unplug: IconNode = [
  ['path', { d: 'm19 5 3-3' }],
  ['path', { d: 'm2 22 3-3' }],
  ['path', { d: 'M6.3 20.3a2.4 2.4 0 0 0 3.4 0L12 18l-6-6-2.3 2.3a2.4 2.4 0 0 0 0 3.4Z' }],
  ['path', { d: 'M7.5 13.5 10 11' }],
  ['path', { d: 'M10.5 16.5 13 14' }],
  ['path', { d: 'm12 6 6 6 2.3-2.3a2.4 2.4 0 0 0 0-3.4l-2.6-2.6a2.4 2.4 0 0 0-3.4 0Z' }],
];

export default Unplug;
