import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name landmark
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iMyIgeDI9IjIxIiB5MT0iMjIiIHkyPSIyMiIgLz4KICA8bGluZSB4MT0iNiIgeDI9IjYiIHkxPSIxOCIgeTI9IjExIiAvPgogIDxsaW5lIHgxPSIxMCIgeDI9IjEwIiB5MT0iMTgiIHkyPSIxMSIgLz4KICA8bGluZSB4MT0iMTQiIHgyPSIxNCIgeTE9IjE4IiB5Mj0iMTEiIC8+CiAgPGxpbmUgeDE9IjE4IiB4Mj0iMTgiIHkxPSIxOCIgeTI9IjExIiAvPgogIDxwb2x5Z29uIHBvaW50cz0iMTIgMiAyMCA3IDQgNyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/landmark
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Landmark: IconNode = [
  ['line', { x1: '3', x2: '21', y1: '22', y2: '22' }],
  ['line', { x1: '6', x2: '6', y1: '18', y2: '11' }],
  ['line', { x1: '10', x2: '10', y1: '18', y2: '11' }],
  ['line', { x1: '14', x2: '14', y1: '18', y2: '11' }],
  ['line', { x1: '18', x2: '18', y1: '18', y2: '11' }],
  ['polygon', { points: '12 2 20 7 4 7' }],
];

export default Landmark;
