import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name shapes
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOC4zIDEwYS43LjcgMCAwIDEtLjYyNi0xLjA3OUwxMS40IDNhLjcuNyAwIDAgMSAxLjE5OC0uMDQzTDE2LjMgOC45YS43LjcgMCAwIDEtLjU3MiAxLjFaIiAvPgogIDxyZWN0IHg9IjMiIHk9IjE0IiB3aWR0aD0iNyIgaGVpZ2h0PSI3IiByeD0iMSIgLz4KICA8Y2lyY2xlIGN4PSIxNy41IiBjeT0iMTcuNSIgcj0iMy41IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/shapes
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Shapes: IconNode = [
  [
    'path',
    {
      d: 'M8.3 10a.7.7 0 0 1-.626-1.079L11.4 3a.7.7 0 0 1 1.198-.043L16.3 8.9a.7.7 0 0 1-.572 1.1Z',
    },
  ],
  ['rect', { x: '3', y: '14', width: '7', height: '7', rx: '1' }],
  ['circle', { cx: '17.5', cy: '17.5', r: '3.5' }],
];

export default Shapes;
