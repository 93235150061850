import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name wine-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOCAyMmg4IiAvPgogIDxwYXRoIGQ9Ik03IDEwaDNtNyAwaC0xLjM0MyIgLz4KICA8cGF0aCBkPSJNMTIgMTV2NyIgLz4KICA8cGF0aCBkPSJNNy4zMDcgNy4zMDdBMTIuMzMgMTIuMzMgMCAwIDAgNyAxMGE1IDUgMCAwIDAgNy4zOTEgNC4zOTFNOC42MzggMi45ODFDOC43NSAyLjY2OCA4Ljg3MiAyLjM0IDkgMmg2YzEuNSA0IDIgNiAyIDggMCAuNDA3LS4wNS44MDktLjE0NSAxLjE5OCIgLz4KICA8bGluZSB4MT0iMiIgeDI9IjIyIiB5MT0iMiIgeTI9IjIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/wine-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const WineOff: IconNode = [
  ['path', { d: 'M8 22h8' }],
  ['path', { d: 'M7 10h3m7 0h-1.343' }],
  ['path', { d: 'M12 15v7' }],
  [
    'path',
    {
      d: 'M7.307 7.307A12.33 12.33 0 0 0 7 10a5 5 0 0 0 7.391 4.391M8.638 2.981C8.75 2.668 8.872 2.34 9 2h6c1.5 4 2 6 2 8 0 .407-.05.809-.145 1.198',
    },
  ],
  ['line', { x1: '2', x2: '22', y1: '2', y2: '22' }],
];

export default WineOff;
