import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name message-circle-dashed
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTMuNSAzLjFjLS41IDAtMS0uMS0xLjUtLjFzLTEgLjEtMS41LjEiIC8+CiAgPHBhdGggZD0iTTE5LjMgNi44YTEwLjQ1IDEwLjQ1IDAgMCAwLTIuMS0yLjEiIC8+CiAgPHBhdGggZD0iTTIwLjkgMTMuNWMuMS0uNS4xLTEgLjEtMS41cy0uMS0xLS4xLTEuNSIgLz4KICA8cGF0aCBkPSJNMTcuMiAxOS4zYTEwLjQ1IDEwLjQ1IDAgMCAwIDIuMS0yLjEiIC8+CiAgPHBhdGggZD0iTTEwLjUgMjAuOWMuNS4xIDEgLjEgMS41LjFzMS0uMSAxLjUtLjEiIC8+CiAgPHBhdGggZD0iTTMuNSAxNy41IDIgMjJsNC41LTEuNSIgLz4KICA8cGF0aCBkPSJNMy4xIDEwLjVjMCAuNS0uMSAxLS4xIDEuNXMuMSAxIC4xIDEuNSIgLz4KICA8cGF0aCBkPSJNNi44IDQuN2ExMC40NSAxMC40NSAwIDAgMC0yLjEgMi4xIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/message-circle-dashed
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MessageCircleDashed: IconNode = [
  ['path', { d: 'M13.5 3.1c-.5 0-1-.1-1.5-.1s-1 .1-1.5.1' }],
  ['path', { d: 'M19.3 6.8a10.45 10.45 0 0 0-2.1-2.1' }],
  ['path', { d: 'M20.9 13.5c.1-.5.1-1 .1-1.5s-.1-1-.1-1.5' }],
  ['path', { d: 'M17.2 19.3a10.45 10.45 0 0 0 2.1-2.1' }],
  ['path', { d: 'M10.5 20.9c.5.1 1 .1 1.5.1s1-.1 1.5-.1' }],
  ['path', { d: 'M3.5 17.5 2 22l4.5-1.5' }],
  ['path', { d: 'M3.1 10.5c0 .5-.1 1-.1 1.5s.1 1 .1 1.5' }],
  ['path', { d: 'M6.8 4.7a10.45 10.45 0 0 0-2.1 2.1' }],
];

export default MessageCircleDashed;
