import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name divide
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjYiIHI9IjEiIC8+CiAgPGxpbmUgeDE9IjUiIHgyPSIxOSIgeTE9IjEyIiB5Mj0iMTIiIC8+CiAgPGNpcmNsZSBjeD0iMTIiIGN5PSIxOCIgcj0iMSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/divide
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Divide: IconNode = [
  ['circle', { cx: '12', cy: '6', r: '1' }],
  ['line', { x1: '5', x2: '19', y1: '12', y2: '12' }],
  ['circle', { cx: '12', cy: '18', r: '1' }],
];

export default Divide;
