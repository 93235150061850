import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name train-track
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAxNyAxNyAyIiAvPgogIDxwYXRoIGQ9Im0yIDE0IDggOCIgLz4KICA8cGF0aCBkPSJtNSAxMSA4IDgiIC8+CiAgPHBhdGggZD0ibTggOCA4IDgiIC8+CiAgPHBhdGggZD0ibTExIDUgOCA4IiAvPgogIDxwYXRoIGQ9Im0xNCAyIDggOCIgLz4KICA8cGF0aCBkPSJNNyAyMiAyMiA3IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/train-track
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TrainTrack: IconNode = [
  ['path', { d: 'M2 17 17 2' }],
  ['path', { d: 'm2 14 8 8' }],
  ['path', { d: 'm5 11 8 8' }],
  ['path', { d: 'm8 8 8 8' }],
  ['path', { d: 'm11 5 8 8' }],
  ['path', { d: 'm14 2 8 8' }],
  ['path', { d: 'M7 22 22 7' }],
];

export default TrainTrack;
