import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name battery-charging
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTUgN2gxYTIgMiAwIDAgMSAyIDJ2NmEyIDIgMCAwIDEtMiAyaC0yIiAvPgogIDxwYXRoIGQ9Ik02IDdINGEyIDIgMCAwIDAtMiAydjZhMiAyIDAgMCAwIDIgMmgxIiAvPgogIDxwYXRoIGQ9Im0xMSA3LTMgNWg0bC0zIDUiIC8+CiAgPGxpbmUgeDE9IjIyIiB4Mj0iMjIiIHkxPSIxMSIgeTI9IjEzIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/battery-charging
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BatteryCharging: IconNode = [
  ['path', { d: 'M15 7h1a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-2' }],
  ['path', { d: 'M6 7H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h1' }],
  ['path', { d: 'm11 7-3 5h4l-3 5' }],
  ['line', { x1: '22', x2: '22', y1: '11', y2: '13' }],
];

export default BatteryCharging;
