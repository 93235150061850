import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name minimize-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cG9seWxpbmUgcG9pbnRzPSI0IDE0IDEwIDE0IDEwIDIwIiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjIwIDEwIDE0IDEwIDE0IDQiIC8+CiAgPGxpbmUgeDE9IjE0IiB4Mj0iMjEiIHkxPSIxMCIgeTI9IjMiIC8+CiAgPGxpbmUgeDE9IjMiIHgyPSIxMCIgeTE9IjIxIiB5Mj0iMTQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/minimize-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Minimize2: IconNode = [
  ['polyline', { points: '4 14 10 14 10 20' }],
  ['polyline', { points: '20 10 14 10 14 4' }],
  ['line', { x1: '14', x2: '21', y1: '10', y2: '3' }],
  ['line', { x1: '3', x2: '10', y1: '21', y2: '14' }],
];

export default Minimize2;
