import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name expand
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTUgMTUgNiA2IiAvPgogIDxwYXRoIGQ9Im0xNSA5IDYtNiIgLz4KICA8cGF0aCBkPSJNMjEgMTYuMlYyMWgtNC44IiAvPgogIDxwYXRoIGQ9Ik0yMSA3LjhWM2gtNC44IiAvPgogIDxwYXRoIGQ9Ik0zIDE2LjJWMjFoNC44IiAvPgogIDxwYXRoIGQ9Im0zIDIxIDYtNiIgLz4KICA8cGF0aCBkPSJNMyA3LjhWM2g0LjgiIC8+CiAgPHBhdGggZD0iTTkgOSAzIDMiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/expand
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Expand: IconNode = [
  ['path', { d: 'm15 15 6 6' }],
  ['path', { d: 'm15 9 6-6' }],
  ['path', { d: 'M21 16.2V21h-4.8' }],
  ['path', { d: 'M21 7.8V3h-4.8' }],
  ['path', { d: 'M3 16.2V21h4.8' }],
  ['path', { d: 'm3 21 6-6' }],
  ['path', { d: 'M3 7.8V3h4.8' }],
  ['path', { d: 'M9 9 3 3' }],
];

export default Expand;
