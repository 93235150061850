import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name grip-horizontal
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjkiIHI9IjEiIC8+CiAgPGNpcmNsZSBjeD0iMTkiIGN5PSI5IiByPSIxIiAvPgogIDxjaXJjbGUgY3g9IjUiIGN5PSI5IiByPSIxIiAvPgogIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTUiIHI9IjEiIC8+CiAgPGNpcmNsZSBjeD0iMTkiIGN5PSIxNSIgcj0iMSIgLz4KICA8Y2lyY2xlIGN4PSI1IiBjeT0iMTUiIHI9IjEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/grip-horizontal
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const GripHorizontal: IconNode = [
  ['circle', { cx: '12', cy: '9', r: '1' }],
  ['circle', { cx: '19', cy: '9', r: '1' }],
  ['circle', { cx: '5', cy: '9', r: '1' }],
  ['circle', { cx: '12', cy: '15', r: '1' }],
  ['circle', { cx: '19', cy: '15', r: '1' }],
  ['circle', { cx: '5', cy: '15', r: '1' }],
];

export default GripHorizontal;
