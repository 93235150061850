import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name highlighter
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtOSAxMS02IDZ2M2g5bDMtMyIgLz4KICA8cGF0aCBkPSJtMjIgMTItNC42IDQuNmEyIDIgMCAwIDEtMi44IDBsLTUuMi01LjJhMiAyIDAgMCAxIDAtMi44TDE0IDQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/highlighter
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Highlighter: IconNode = [
  ['path', { d: 'm9 11-6 6v3h9l3-3' }],
  ['path', { d: 'm22 12-4.6 4.6a2 2 0 0 1-2.8 0l-5.2-5.2a2 2 0 0 1 0-2.8L14 4' }],
];

export default Highlighter;
