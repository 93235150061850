import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name letter-text
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTUgMTJoNiIgLz4KICA8cGF0aCBkPSJNMTUgNmg2IiAvPgogIDxwYXRoIGQ9Im0zIDEzIDMuNTUzLTcuNzI0YS41LjUgMCAwIDEgLjg5NCAwTDExIDEzIiAvPgogIDxwYXRoIGQ9Ik0zIDE4aDE4IiAvPgogIDxwYXRoIGQ9Ik00IDExaDYiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/letter-text
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const LetterText: IconNode = [
  ['path', { d: 'M15 12h6' }],
  ['path', { d: 'M15 6h6' }],
  ['path', { d: 'm3 13 3.553-7.724a.5.5 0 0 1 .894 0L11 13' }],
  ['path', { d: 'M3 18h18' }],
  ['path', { d: 'M4 11h6' }],
];

export default LetterText;
