import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name gamepad
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iNiIgeDI9IjEwIiB5MT0iMTIiIHkyPSIxMiIgLz4KICA8bGluZSB4MT0iOCIgeDI9IjgiIHkxPSIxMCIgeTI9IjE0IiAvPgogIDxsaW5lIHgxPSIxNSIgeDI9IjE1LjAxIiB5MT0iMTMiIHkyPSIxMyIgLz4KICA8bGluZSB4MT0iMTgiIHgyPSIxOC4wMSIgeTE9IjExIiB5Mj0iMTEiIC8+CiAgPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjEyIiB4PSIyIiB5PSI2IiByeD0iMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/gamepad
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Gamepad: IconNode = [
  ['line', { x1: '6', x2: '10', y1: '12', y2: '12' }],
  ['line', { x1: '8', x2: '8', y1: '10', y2: '14' }],
  ['line', { x1: '15', x2: '15.01', y1: '13', y2: '13' }],
  ['line', { x1: '18', x2: '18.01', y1: '11', y2: '11' }],
  ['rect', { width: '20', height: '12', x: '2', y: '6', rx: '2' }],
];

export default Gamepad;
