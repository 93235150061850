import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name door-open
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTMgNGgzYTIgMiAwIDAgMSAyIDJ2MTQiIC8+CiAgPHBhdGggZD0iTTIgMjBoMyIgLz4KICA8cGF0aCBkPSJNMTMgMjBoOSIgLz4KICA8cGF0aCBkPSJNMTAgMTJ2LjAxIiAvPgogIDxwYXRoIGQ9Ik0xMyA0LjU2MnYxNi4xNTdhMSAxIDAgMCAxLTEuMjQyLjk3TDUgMjBWNS41NjJhMiAyIDAgMCAxIDEuNTE1LTEuOTRsNC0xQTIgMiAwIDAgMSAxMyA0LjU2MVoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/door-open
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const DoorOpen: IconNode = [
  ['path', { d: 'M13 4h3a2 2 0 0 1 2 2v14' }],
  ['path', { d: 'M2 20h3' }],
  ['path', { d: 'M13 20h9' }],
  ['path', { d: 'M10 12v.01' }],
  [
    'path',
    {
      d: 'M13 4.562v16.157a1 1 0 0 1-1.242.97L5 20V5.562a2 2 0 0 1 1.515-1.94l4-1A2 2 0 0 1 13 4.561Z',
    },
  ],
];

export default DoorOpen;
