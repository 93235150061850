import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name lamp-wall-down
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMTNoNmwzIDdIOGwzLTdaIiAvPgogIDxwYXRoIGQ9Ik0xNCAxM1Y4YTIgMiAwIDAgMC0yLTJIOCIgLz4KICA8cGF0aCBkPSJNNCA5aDJhMiAyIDAgMCAwIDItMlY1YTIgMiAwIDAgMC0yLTJINHY2WiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/lamp-wall-down
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const LampWallDown: IconNode = [
  ['path', { d: 'M11 13h6l3 7H8l3-7Z' }],
  ['path', { d: 'M14 13V8a2 2 0 0 0-2-2H8' }],
  ['path', { d: 'M4 9h2a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H4v6Z' }],
];

export default LampWallDown;
