import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name university
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEwIiByPSIxIiAvPgogIDxwYXRoIGQ9Ik0yMiAyMFY4aC00bC02LTQtNiA0SDJ2MTJhMiAyIDAgMCAwIDIgMmgxNmEyIDIgMCAwIDAgMi0yIiAvPgogIDxwYXRoIGQ9Ik02IDE3di4wMSIgLz4KICA8cGF0aCBkPSJNNiAxM3YuMDEiIC8+CiAgPHBhdGggZD0iTTE4IDE3di4wMSIgLz4KICA8cGF0aCBkPSJNMTggMTN2LjAxIiAvPgogIDxwYXRoIGQ9Ik0xNCAyMnYtNWEyIDIgMCAwIDAtMi0yYTIgMiAwIDAgMC0yIDJ2NSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/university
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const University: IconNode = [
  ['circle', { cx: '12', cy: '10', r: '1' }],
  ['path', { d: 'M22 20V8h-4l-6-4-6 4H2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2' }],
  ['path', { d: 'M6 17v.01' }],
  ['path', { d: 'M6 13v.01' }],
  ['path', { d: 'M18 17v.01' }],
  ['path', { d: 'M18 13v.01' }],
  ['path', { d: 'M14 22v-5a2 2 0 0 0-2-2a2 2 0 0 0-2 2v5' }],
];

export default University;
