import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cup-soda
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtNiA4IDEuNzUgMTIuMjhhMiAyIDAgMCAwIDIgMS43Mmg0LjU0YTIgMiAwIDAgMCAyLTEuNzJMMTggOCIgLz4KICA8cGF0aCBkPSJNNSA4aDE0IiAvPgogIDxwYXRoIGQ9Ik03IDE1YTYuNDcgNi40NyAwIDAgMSA1IDAgNi40NyA2LjQ3IDAgMCAwIDUgMCIgLz4KICA8cGF0aCBkPSJtMTIgOCAxLTZoMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/cup-soda
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CupSoda: IconNode = [
  ['path', { d: 'm6 8 1.75 12.28a2 2 0 0 0 2 1.72h4.54a2 2 0 0 0 2-1.72L18 8' }],
  ['path', { d: 'M5 8h14' }],
  ['path', { d: 'M7 15a6.47 6.47 0 0 1 5 0 6.47 6.47 0 0 0 5 0' }],
  ['path', { d: 'm12 8 1-6h2' }],
];

export default CupSoda;
