import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name align-start-horizontal
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iNiIgaGVpZ2h0PSIxNiIgeD0iNCIgeT0iNiIgcng9IjIiIC8+CiAgPHJlY3Qgd2lkdGg9IjYiIGhlaWdodD0iOSIgeD0iMTQiIHk9IjYiIHJ4PSIyIiAvPgogIDxwYXRoIGQ9Ik0yMiAySDIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/align-start-horizontal
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const AlignStartHorizontal: IconNode = [
  ['rect', { width: '6', height: '16', x: '4', y: '6', rx: '2' }],
  ['rect', { width: '6', height: '9', x: '14', y: '6', rx: '2' }],
  ['path', { d: 'M22 2H2' }],
];

export default AlignStartHorizontal;
