import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name align-vertical-distribute-end
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMTQiIGhlaWdodD0iNiIgeD0iNSIgeT0iMTQiIHJ4PSIyIiAvPgogIDxyZWN0IHdpZHRoPSIxMCIgaGVpZ2h0PSI2IiB4PSI3IiB5PSI0IiByeD0iMiIgLz4KICA8cGF0aCBkPSJNMiAyMGgyMCIgLz4KICA8cGF0aCBkPSJNMiAxMGgyMCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/align-vertical-distribute-end
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const AlignVerticalDistributeEnd: IconNode = [
  ['rect', { width: '14', height: '6', x: '5', y: '14', rx: '2' }],
  ['rect', { width: '10', height: '6', x: '7', y: '4', rx: '2' }],
  ['path', { d: 'M2 20h20' }],
  ['path', { d: 'M2 10h20' }],
];

export default AlignVerticalDistributeEnd;
