import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name radio-tower
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNC45IDE2LjFDMSAxMi4yIDEgNS44IDQuOSAxLjkiIC8+CiAgPHBhdGggZD0iTTcuOCA0LjdhNi4xNCA2LjE0IDAgMCAwLS44IDcuNSIgLz4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjkiIHI9IjIiIC8+CiAgPHBhdGggZD0iTTE2LjIgNC44YzIgMiAyLjI2IDUuMTEuOCA3LjQ3IiAvPgogIDxwYXRoIGQ9Ik0xOS4xIDEuOWE5Ljk2IDkuOTYgMCAwIDEgMCAxNC4xIiAvPgogIDxwYXRoIGQ9Ik05LjUgMThoNSIgLz4KICA8cGF0aCBkPSJtOCAyMiA0LTExIDQgMTEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/radio-tower
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const RadioTower: IconNode = [
  ['path', { d: 'M4.9 16.1C1 12.2 1 5.8 4.9 1.9' }],
  ['path', { d: 'M7.8 4.7a6.14 6.14 0 0 0-.8 7.5' }],
  ['circle', { cx: '12', cy: '9', r: '2' }],
  ['path', { d: 'M16.2 4.8c2 2 2.26 5.11.8 7.47' }],
  ['path', { d: 'M19.1 1.9a9.96 9.96 0 0 1 0 14.1' }],
  ['path', { d: 'M9.5 18h5' }],
  ['path', { d: 'm8 22 4-11 4 11' }],
];

export default RadioTower;
