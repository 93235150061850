import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name flip-horizontal-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMyA3IDUgNS01IDVWNyIgLz4KICA8cGF0aCBkPSJtMjEgNy01IDUgNSA1VjciIC8+CiAgPHBhdGggZD0iTTEyIDIwdjIiIC8+CiAgPHBhdGggZD0iTTEyIDE0djIiIC8+CiAgPHBhdGggZD0iTTEyIDh2MiIgLz4KICA8cGF0aCBkPSJNMTIgMnYyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/flip-horizontal-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FlipHorizontal2: IconNode = [
  ['path', { d: 'm3 7 5 5-5 5V7' }],
  ['path', { d: 'm21 7-5 5 5 5V7' }],
  ['path', { d: 'M12 20v2' }],
  ['path', { d: 'M12 14v2' }],
  ['path', { d: 'M12 8v2' }],
  ['path', { d: 'M12 2v2' }],
];

export default FlipHorizontal2;
