import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name feather
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIuNjcgMTlhMiAyIDAgMCAwIDEuNDE2LS41ODhsNi4xNTQtNi4xNzJhNiA2IDAgMCAwLTguNDktOC40OUw1LjU4NiA5LjkxNEEyIDIgMCAwIDAgNSAxMS4zMjhWMThhMSAxIDAgMCAwIDEgMXoiIC8+CiAgPHBhdGggZD0iTTE2IDggMiAyMiIgLz4KICA8cGF0aCBkPSJNMTcuNSAxNUg5IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/feather
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Feather: IconNode = [
  [
    'path',
    {
      d: 'M12.67 19a2 2 0 0 0 1.416-.588l6.154-6.172a6 6 0 0 0-8.49-8.49L5.586 9.914A2 2 0 0 0 5 11.328V18a1 1 0 0 0 1 1z',
    },
  ],
  ['path', { d: 'M16 8 2 22' }],
  ['path', { d: 'M17.5 15H9' }],
];

export default Feather;
