import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name wrap-text
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iMyIgeDI9IjIxIiB5MT0iNiIgeTI9IjYiIC8+CiAgPHBhdGggZD0iTTMgMTJoMTVhMyAzIDAgMSAxIDAgNmgtNCIgLz4KICA8cG9seWxpbmUgcG9pbnRzPSIxNiAxNiAxNCAxOCAxNiAyMCIgLz4KICA8bGluZSB4MT0iMyIgeDI9IjEwIiB5MT0iMTgiIHkyPSIxOCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/wrap-text
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const WrapText: IconNode = [
  ['line', { x1: '3', x2: '21', y1: '6', y2: '6' }],
  ['path', { d: 'M3 12h15a3 3 0 1 1 0 6h-4' }],
  ['polyline', { points: '16 16 14 18 16 20' }],
  ['line', { x1: '3', x2: '10', y1: '18', y2: '18' }],
];

export default WrapText;
