import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name fire-extinguisher
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTUgNi41VjNhMSAxIDAgMCAwLTEtMWgtMmExIDEgMCAwIDAtMSAxdjMuNSIgLz4KICA8cGF0aCBkPSJNOSAxOGg4IiAvPgogIDxwYXRoIGQ9Ik0xOCAzaC0zIiAvPgogIDxwYXRoIGQ9Ik0xMSAzYTYgNiAwIDAgMC02IDZ2MTEiIC8+CiAgPHBhdGggZD0iTTUgMTNoNCIgLz4KICA8cGF0aCBkPSJNMTcgMTBhNCA0IDAgMCAwLTggMHYxMGEyIDIgMCAwIDAgMiAyaDRhMiAyIDAgMCAwIDItMloiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/fire-extinguisher
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FireExtinguisher: IconNode = [
  ['path', { d: 'M15 6.5V3a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v3.5' }],
  ['path', { d: 'M9 18h8' }],
  ['path', { d: 'M18 3h-3' }],
  ['path', { d: 'M11 3a6 6 0 0 0-6 6v11' }],
  ['path', { d: 'M5 13h4' }],
  ['path', { d: 'M17 10a4 4 0 0 0-8 0v10a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2Z' }],
];

export default FireExtinguisher;
