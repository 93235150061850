import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name database-backup
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8ZWxsaXBzZSBjeD0iMTIiIGN5PSI1IiByeD0iOSIgcnk9IjMiIC8+CiAgPHBhdGggZD0iTTMgMTJhOSAzIDAgMCAwIDUgMi42OSIgLz4KICA8cGF0aCBkPSJNMjEgOS4zVjUiIC8+CiAgPHBhdGggZD0iTTMgNXYxNGE5IDMgMCAwIDAgNi40NyAyLjg4IiAvPgogIDxwYXRoIGQ9Ik0xMiAxMnY0aDQiIC8+CiAgPHBhdGggZD0iTTEzIDIwYTUgNSAwIDAgMCA5LTMgNC41IDQuNSAwIDAgMC00LjUtNC41Yy0xLjMzIDAtMi41NC41NC0zLjQxIDEuNDFMMTIgMTYiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/database-backup
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const DatabaseBackup: IconNode = [
  ['ellipse', { cx: '12', cy: '5', rx: '9', ry: '3' }],
  ['path', { d: 'M3 12a9 3 0 0 0 5 2.69' }],
  ['path', { d: 'M21 9.3V5' }],
  ['path', { d: 'M3 5v14a9 3 0 0 0 6.47 2.88' }],
  ['path', { d: 'M12 12v4h4' }],
  ['path', { d: 'M13 20a5 5 0 0 0 9-3 4.5 4.5 0 0 0-4.5-4.5c-1.33 0-2.54.54-3.41 1.41L12 16' }],
];

export default DatabaseBackup;
