import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name merge
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtOCA2IDQtNCA0IDQiIC8+CiAgPHBhdGggZD0iTTEyIDJ2MTAuM2E0IDQgMCAwIDEtMS4xNzIgMi44NzJMNCAyMiIgLz4KICA8cGF0aCBkPSJtMjAgMjItNS01IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/merge
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Merge: IconNode = [
  ['path', { d: 'm8 6 4-4 4 4' }],
  ['path', { d: 'M12 2v10.3a4 4 0 0 1-1.172 2.872L4 22' }],
  ['path', { d: 'm20 22-5-5' }],
];

export default Merge;
