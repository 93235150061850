import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name aperture
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIxMCIgLz4KICA8cGF0aCBkPSJtMTQuMzEgOCA1Ljc0IDkuOTQiIC8+CiAgPHBhdGggZD0iTTkuNjkgOGgxMS40OCIgLz4KICA8cGF0aCBkPSJtNy4zOCAxMiA1Ljc0LTkuOTQiIC8+CiAgPHBhdGggZD0iTTkuNjkgMTYgMy45NSA2LjA2IiAvPgogIDxwYXRoIGQ9Ik0xNC4zMSAxNkgyLjgzIiAvPgogIDxwYXRoIGQ9Im0xNi42MiAxMi01Ljc0IDkuOTQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/aperture
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Aperture: IconNode = [
  ['circle', { cx: '12', cy: '12', r: '10' }],
  ['path', { d: 'm14.31 8 5.74 9.94' }],
  ['path', { d: 'M9.69 8h11.48' }],
  ['path', { d: 'm7.38 12 5.74-9.94' }],
  ['path', { d: 'M9.69 16 3.95 6.06' }],
  ['path', { d: 'M14.31 16H2.83' }],
  ['path', { d: 'm16.62 12-5.74 9.94' }],
];

export default Aperture;
