import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name ligature
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOCAyMFY4YzAtMi4yIDEuOC00IDQtNCAxLjUgMCAyLjguOCAzLjUgMiIgLz4KICA8cGF0aCBkPSJNNiAxMmg0IiAvPgogIDxwYXRoIGQ9Ik0xNCAxMmgydjgiIC8+CiAgPHBhdGggZD0iTTYgMjBoNCIgLz4KICA8cGF0aCBkPSJNMTQgMjBoNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/ligature
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Ligature: IconNode = [
  ['path', { d: 'M8 20V8c0-2.2 1.8-4 4-4 1.5 0 2.8.8 3.5 2' }],
  ['path', { d: 'M6 12h4' }],
  ['path', { d: 'M14 12h2v8' }],
  ['path', { d: 'M6 20h4' }],
  ['path', { d: 'M14 20h4' }],
];

export default Ligature;
