import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name carrot
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMi4yNyAyMS43czkuODctMy41IDEyLjczLTYuMzZhNC41IDQuNSAwIDAgMC02LjM2LTYuMzdDNS43NyAxMS44NCAyLjI3IDIxLjcgMi4yNyAyMS43ek04LjY0IDE0bC0yLjA1LTIuMDRNMTUuMzQgMTVsLTIuNDYtMi40NiIgLz4KICA8cGF0aCBkPSJNMjIgOXMtMS4zMy0yLTMuNS0yQzE2Ljg2IDcgMTUgOSAxNSA5czEuMzMgMiAzLjUgMlMyMiA5IDIyIDl6IiAvPgogIDxwYXRoIGQ9Ik0xNSAycy0yIDEuMzMtMiAzLjVTMTUgOSAxNSA5czItMS44NCAyLTMuNUMxNyAzLjMzIDE1IDIgMTUgMnoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/carrot
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Carrot: IconNode = [
  [
    'path',
    {
      d: 'M2.27 21.7s9.87-3.5 12.73-6.36a4.5 4.5 0 0 0-6.36-6.37C5.77 11.84 2.27 21.7 2.27 21.7zM8.64 14l-2.05-2.04M15.34 15l-2.46-2.46',
    },
  ],
  ['path', { d: 'M22 9s-1.33-2-3.5-2C16.86 7 15 9 15 9s1.33 2 3.5 2S22 9 22 9z' }],
  ['path', { d: 'M15 2s-2 1.33-2 3.5S15 9 15 9s2-1.84 2-3.5C17 3.33 15 2 15 2z' }],
];

export default Carrot;
