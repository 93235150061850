import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name align-horizontal-distribute-center
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iNiIgaGVpZ2h0PSIxNCIgeD0iNCIgeT0iNSIgcng9IjIiIC8+CiAgPHJlY3Qgd2lkdGg9IjYiIGhlaWdodD0iMTAiIHg9IjE0IiB5PSI3IiByeD0iMiIgLz4KICA8cGF0aCBkPSJNMTcgMjJ2LTUiIC8+CiAgPHBhdGggZD0iTTE3IDdWMiIgLz4KICA8cGF0aCBkPSJNNyAyMnYtMyIgLz4KICA8cGF0aCBkPSJNNyA1VjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/align-horizontal-distribute-center
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const AlignHorizontalDistributeCenter: IconNode = [
  ['rect', { width: '6', height: '14', x: '4', y: '5', rx: '2' }],
  ['rect', { width: '6', height: '10', x: '14', y: '7', rx: '2' }],
  ['path', { d: 'M17 22v-5' }],
  ['path', { d: 'M17 7V2' }],
  ['path', { d: 'M7 22v-3' }],
  ['path', { d: 'M7 5V2' }],
];

export default AlignHorizontalDistributeCenter;
