import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name step-forward
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iNiIgeDI9IjYiIHkxPSI0IiB5Mj0iMjAiIC8+CiAgPHBvbHlnb24gcG9pbnRzPSIxMCw0IDIwLDEyIDEwLDIwIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/step-forward
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const StepForward: IconNode = [
  ['line', { x1: '6', x2: '6', y1: '4', y2: '20' }],
  ['polygon', { points: '10,4 20,12 10,20' }],
];

export default StepForward;
