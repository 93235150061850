import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name bean-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOSA5Yy0uNjQuNjQtMS41MjEuOTU0LTIuNDAyIDEuMTY1QTYgNiAwIDAgMCA4IDIyYTEzLjk2IDEzLjk2IDAgMCAwIDkuOS00LjEiIC8+CiAgPHBhdGggZD0iTTEwLjc1IDUuMDkzQTYgNiAwIDAgMSAyMiA4YzAgMi40MTEtLjYxIDQuNjgtMS42ODMgNi42NiIgLz4KICA8cGF0aCBkPSJNNS4zNDEgMTAuNjJhNCA0IDAgMCAwIDYuNDg3IDEuMjA4TTEwLjYyIDUuMzQxYTQuMDE1IDQuMDE1IDAgMCAxIDIuMDM5IDIuMDQiIC8+CiAgPGxpbmUgeDE9IjIiIHgyPSIyMiIgeTE9IjIiIHkyPSIyMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/bean-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BeanOff: IconNode = [
  ['path', { d: 'M9 9c-.64.64-1.521.954-2.402 1.165A6 6 0 0 0 8 22a13.96 13.96 0 0 0 9.9-4.1' }],
  ['path', { d: 'M10.75 5.093A6 6 0 0 1 22 8c0 2.411-.61 4.68-1.683 6.66' }],
  ['path', { d: 'M5.341 10.62a4 4 0 0 0 6.487 1.208M10.62 5.341a4.015 4.015 0 0 1 2.039 2.04' }],
  ['line', { x1: '2', x2: '22', y1: '2', y2: '22' }],
];

export default BeanOff;
