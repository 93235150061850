import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name file-key-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAyMmgxNGEyIDIgMCAwIDAgMi0yVjdsLTUtNUg2YTIgMiAwIDAgMC0yIDJ2NiIgLz4KICA8cGF0aCBkPSJNMTQgMnY0YTIgMiAwIDAgMCAyIDJoNCIgLz4KICA8Y2lyY2xlIGN4PSI0IiBjeT0iMTYiIHI9IjIiIC8+CiAgPHBhdGggZD0ibTEwIDEwLTQuNSA0LjUiIC8+CiAgPHBhdGggZD0ibTkgMTEgMSAxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/file-key-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FileKey2: IconNode = [
  ['path', { d: 'M4 22h14a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v6' }],
  ['path', { d: 'M14 2v4a2 2 0 0 0 2 2h4' }],
  ['circle', { cx: '4', cy: '16', r: '2' }],
  ['path', { d: 'm10 10-4.5 4.5' }],
  ['path', { d: 'm9 11 1 1' }],
];

export default FileKey2;
