import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name slack
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMyIgaGVpZ2h0PSI4IiB4PSIxMyIgeT0iMiIgcng9IjEuNSIgLz4KICA8cGF0aCBkPSJNMTkgOC41VjEwaDEuNUExLjUgMS41IDAgMSAwIDE5IDguNSIgLz4KICA8cmVjdCB3aWR0aD0iMyIgaGVpZ2h0PSI4IiB4PSI4IiB5PSIxNCIgcng9IjEuNSIgLz4KICA8cGF0aCBkPSJNNSAxNS41VjE0SDMuNUExLjUgMS41IDAgMSAwIDUgMTUuNSIgLz4KICA8cmVjdCB3aWR0aD0iOCIgaGVpZ2h0PSIzIiB4PSIxNCIgeT0iMTMiIHJ4PSIxLjUiIC8+CiAgPHBhdGggZD0iTTE1LjUgMTlIMTR2MS41YTEuNSAxLjUgMCAxIDAgMS41LTEuNSIgLz4KICA8cmVjdCB3aWR0aD0iOCIgaGVpZ2h0PSIzIiB4PSIyIiB5PSI4IiByeD0iMS41IiAvPgogIDxwYXRoIGQ9Ik04LjUgNUgxMFYzLjVBMS41IDEuNSAwIDEgMCA4LjUgNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/slack
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 * @deprecated Brand icons have been deprecated and are due to be removed, please refer to https://github.com/lucide-icons/lucide/issues/670. We recommend using https://simpleicons.org/?q=slack instead. This icon will be removed in v1.0
 */
const Slack: IconNode = [
  ['rect', { width: '3', height: '8', x: '13', y: '2', rx: '1.5' }],
  ['path', { d: 'M19 8.5V10h1.5A1.5 1.5 0 1 0 19 8.5' }],
  ['rect', { width: '3', height: '8', x: '8', y: '14', rx: '1.5' }],
  ['path', { d: 'M5 15.5V14H3.5A1.5 1.5 0 1 0 5 15.5' }],
  ['rect', { width: '8', height: '3', x: '14', y: '13', rx: '1.5' }],
  ['path', { d: 'M15.5 19H14v1.5a1.5 1.5 0 1 0 1.5-1.5' }],
  ['rect', { width: '8', height: '3', x: '2', y: '8', rx: '1.5' }],
  ['path', { d: 'M8.5 5H10V3.5A1.5 1.5 0 1 0 8.5 5' }],
];

export default Slack;
