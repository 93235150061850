import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name mouse-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgNnYuMzQzIiAvPgogIDxwYXRoIGQ9Ik0xOC4yMTggMTguMjE4QTcgNyAwIDAgMSA1IDE1VjlhNyA3IDAgMCAxIC43ODItMy4yMTgiIC8+CiAgPHBhdGggZD0iTTE5IDEzLjM0M1Y5QTcgNyAwIDAgMCA4LjU2IDIuOTAyIiAvPgogIDxwYXRoIGQ9Ik0yMiAyMiAyIDIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/mouse-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MouseOff: IconNode = [
  ['path', { d: 'M12 6v.343' }],
  ['path', { d: 'M18.218 18.218A7 7 0 0 1 5 15V9a7 7 0 0 1 .782-3.218' }],
  ['path', { d: 'M19 13.343V9A7 7 0 0 0 8.56 2.902' }],
  ['path', { d: 'M22 22 2 2' }],
];

export default MouseOff;
