import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name move-diagonal
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMTlINXYtNiIgLz4KICA8cGF0aCBkPSJNMTMgNWg2djYiIC8+CiAgPHBhdGggZD0iTTE5IDUgNSAxOSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/move-diagonal
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MoveDiagonal: IconNode = [
  ['path', { d: 'M11 19H5v-6' }],
  ['path', { d: 'M13 5h6v6' }],
  ['path', { d: 'M19 5 5 19' }],
];

export default MoveDiagonal;
