import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name send-horizontal
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMy43MTQgMy4wNDhhLjQ5OC40OTggMCAwIDAtLjY4My42MjdsMi44NDMgNy42MjdhMiAyIDAgMCAxIDAgMS4zOTZsLTIuODQyIDcuNjI3YS40OTguNDk4IDAgMCAwIC42ODIuNjI3bDE4LTguNWEuNS41IDAgMCAwIDAtLjkwNHoiIC8+CiAgPHBhdGggZD0iTTYgMTJoMTYiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/send-horizontal
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const SendHorizontal: IconNode = [
  [
    'path',
    {
      d: 'M3.714 3.048a.498.498 0 0 0-.683.627l2.843 7.627a2 2 0 0 1 0 1.396l-2.842 7.627a.498.498 0 0 0 .682.627l18-8.5a.5.5 0 0 0 0-.904z',
    },
  ],
  ['path', { d: 'M6 12h16' }],
];

export default SendHorizontal;
