import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name heading
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNiAxMmgxMiIgLz4KICA8cGF0aCBkPSJNNiAyMFY0IiAvPgogIDxwYXRoIGQ9Ik0xOCAyMFY0IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/heading
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Heading: IconNode = [
  ['path', { d: 'M6 12h12' }],
  ['path', { d: 'M6 20V4' }],
  ['path', { d: 'M18 20V4' }],
];

export default Heading;
