import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cast
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiA4VjZhMiAyIDAgMCAxIDItMmgxNmEyIDIgMCAwIDEgMiAydjEyYTIgMiAwIDAgMS0yIDJoLTYiIC8+CiAgPHBhdGggZD0iTTIgMTJhOSA5IDAgMCAxIDggOCIgLz4KICA8cGF0aCBkPSJNMiAxNmE1IDUgMCAwIDEgNCA0IiAvPgogIDxsaW5lIHgxPSIyIiB4Mj0iMi4wMSIgeTE9IjIwIiB5Mj0iMjAiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/cast
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Cast: IconNode = [
  ['path', { d: 'M2 8V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-6' }],
  ['path', { d: 'M2 12a9 9 0 0 1 8 8' }],
  ['path', { d: 'M2 16a5 5 0 0 1 4 4' }],
  ['line', { x1: '2', x2: '2.01', y1: '20', y2: '20' }],
];

export default Cast;
