import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name sun-moon
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgOGEyLjgzIDIuODMgMCAwIDAgNCA0IDQgNCAwIDEgMS00LTQiIC8+CiAgPHBhdGggZD0iTTEyIDJ2MiIgLz4KICA8cGF0aCBkPSJNMTIgMjB2MiIgLz4KICA8cGF0aCBkPSJtNC45IDQuOSAxLjQgMS40IiAvPgogIDxwYXRoIGQ9Im0xNy43IDE3LjcgMS40IDEuNCIgLz4KICA8cGF0aCBkPSJNMiAxMmgyIiAvPgogIDxwYXRoIGQ9Ik0yMCAxMmgyIiAvPgogIDxwYXRoIGQ9Im02LjMgMTcuNy0xLjQgMS40IiAvPgogIDxwYXRoIGQ9Im0xOS4xIDQuOS0xLjQgMS40IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/sun-moon
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const SunMoon: IconNode = [
  ['path', { d: 'M12 8a2.83 2.83 0 0 0 4 4 4 4 0 1 1-4-4' }],
  ['path', { d: 'M12 2v2' }],
  ['path', { d: 'M12 20v2' }],
  ['path', { d: 'm4.9 4.9 1.4 1.4' }],
  ['path', { d: 'm17.7 17.7 1.4 1.4' }],
  ['path', { d: 'M2 12h2' }],
  ['path', { d: 'M20 12h2' }],
  ['path', { d: 'm6.3 17.7-1.4 1.4' }],
  ['path', { d: 'm19.1 4.9-1.4 1.4' }],
];

export default SunMoon;
