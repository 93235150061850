import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name align-center-vertical
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMnYyMCIgLz4KICA8cGF0aCBkPSJNOCAxMEg0YTIgMiAwIDAgMS0yLTJWNmMwLTEuMS45LTIgMi0yaDQiIC8+CiAgPHBhdGggZD0iTTE2IDEwaDRhMiAyIDAgMCAwIDItMlY2YTIgMiAwIDAgMC0yLTJoLTQiIC8+CiAgPHBhdGggZD0iTTggMjBIN2EyIDIgMCAwIDEtMi0ydi0yYzAtMS4xLjktMiAyLTJoMSIgLz4KICA8cGF0aCBkPSJNMTYgMTRoMWEyIDIgMCAwIDEgMiAydjJhMiAyIDAgMCAxLTIgMmgtMSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/align-center-vertical
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const AlignCenterVertical: IconNode = [
  ['path', { d: 'M12 2v20' }],
  ['path', { d: 'M8 10H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h4' }],
  ['path', { d: 'M16 10h4a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-4' }],
  ['path', { d: 'M8 20H7a2 2 0 0 1-2-2v-2c0-1.1.9-2 2-2h1' }],
  ['path', { d: 'M16 14h1a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-1' }],
];

export default AlignCenterVertical;
