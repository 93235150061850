import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name message-circle-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjAuNSAxNC45QTkgOSAwIDAgMCA5LjEgMy41IiAvPgogIDxwYXRoIGQ9Im0yIDIgMjAgMjAiIC8+CiAgPHBhdGggZD0iTTUuNiA1LjZDMyA4LjMgMi4yIDEyLjUgNCAxNmwtMiA2IDYtMmMzLjQgMS44IDcuNiAxLjEgMTAuMy0xLjciIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/message-circle-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MessageCircleOff: IconNode = [
  ['path', { d: 'M20.5 14.9A9 9 0 0 0 9.1 3.5' }],
  ['path', { d: 'm2 2 20 20' }],
  ['path', { d: 'M5.6 5.6C3 8.3 2.2 12.5 4 16l-2 6 6-2c3.4 1.8 7.6 1.1 10.3-1.7' }],
];

export default MessageCircleOff;
