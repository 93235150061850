import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name table-rows-split
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQgMTBoMiIgLz4KICA8cGF0aCBkPSJNMTUgMjJ2LTgiIC8+CiAgPHBhdGggZD0iTTE1IDJ2NCIgLz4KICA8cGF0aCBkPSJNMiAxMGgyIiAvPgogIDxwYXRoIGQ9Ik0yMCAxMGgyIiAvPgogIDxwYXRoIGQ9Ik0zIDE5aDE4IiAvPgogIDxwYXRoIGQ9Ik0zIDIydi02YTIgMiAxMzUgMCAxIDItMmgxNGEyIDIgNDUgMCAxIDIgMnY2IiAvPgogIDxwYXRoIGQ9Ik0zIDJ2MmEyIDIgNDUgMCAwIDIgMmgxNGEyIDIgMTM1IDAgMCAyLTJWMiIgLz4KICA8cGF0aCBkPSJNOCAxMGgyIiAvPgogIDxwYXRoIGQ9Ik05IDIydi04IiAvPgogIDxwYXRoIGQ9Ik05IDJ2NCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/table-rows-split
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TableRowsSplit: IconNode = [
  ['path', { d: 'M14 10h2' }],
  ['path', { d: 'M15 22v-8' }],
  ['path', { d: 'M15 2v4' }],
  ['path', { d: 'M2 10h2' }],
  ['path', { d: 'M20 10h2' }],
  ['path', { d: 'M3 19h18' }],
  ['path', { d: 'M3 22v-6a2 2 135 0 1 2-2h14a2 2 45 0 1 2 2v6' }],
  ['path', { d: 'M3 2v2a2 2 45 0 0 2 2h14a2 2 135 0 0 2-2V2' }],
  ['path', { d: 'M8 10h2' }],
  ['path', { d: 'M9 22v-8' }],
  ['path', { d: 'M9 2v4' }],
];

export default TableRowsSplit;
