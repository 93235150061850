import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name banknote-arrow-up
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMThINGEyIDIgMCAwIDEtMi0yVjhhMiAyIDAgMCAxIDItMmgxNmEyIDIgMCAwIDEgMiAydjUiIC8+CiAgPHBhdGggZD0iTTE4IDEyaC4wMSIgLz4KICA8cGF0aCBkPSJNMTkgMjJ2LTYiIC8+CiAgPHBhdGggZD0ibTIyIDE5LTMtMy0zIDMiIC8+CiAgPHBhdGggZD0iTTYgMTJoLjAxIiAvPgogIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/banknote-arrow-up
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BanknoteArrowUp: IconNode = [
  ['path', { d: 'M12 18H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5' }],
  ['path', { d: 'M18 12h.01' }],
  ['path', { d: 'M19 22v-6' }],
  ['path', { d: 'm22 19-3-3-3 3' }],
  ['path', { d: 'M6 12h.01' }],
  ['circle', { cx: '12', cy: '12', r: '2' }],
];

export default BanknoteArrowUp;
