import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name plane-landing
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAyMmgyMCIgLz4KICA8cGF0aCBkPSJNMy43NyAxMC43NyAyIDlsMi00LjUgMS4xLjU1Yy41NS4yOC45Ljg0LjkgMS40NXMuMzUgMS4xNy45IDEuNDVMOCA4LjVsMy02IDEuMDUuNTNhMiAyIDAgMCAxIDEuMDkgMS41MmwuNzIgNS40YTIgMiAwIDAgMCAxLjA5IDEuNTJsNC40IDIuMmMuNDIuMjIuNzguNTUgMS4wMS45NmwuNiAxLjAzYy40OS44OC0uMDYgMS45OC0xLjA2IDIuMWwtMS4xOC4xNWMtLjQ3LjA2LS45NS0uMDItMS4zNy0uMjRMNC4yOSAxMS4xNWEyIDIgMCAwIDEtLjUyLS4zOFoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/plane-landing
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const PlaneLanding: IconNode = [
  ['path', { d: 'M2 22h20' }],
  [
    'path',
    {
      d: 'M3.77 10.77 2 9l2-4.5 1.1.55c.55.28.9.84.9 1.45s.35 1.17.9 1.45L8 8.5l3-6 1.05.53a2 2 0 0 1 1.09 1.52l.72 5.4a2 2 0 0 0 1.09 1.52l4.4 2.2c.42.22.78.55 1.01.96l.6 1.03c.49.88-.06 1.98-1.06 2.1l-1.18.15c-.47.06-.95-.02-1.37-.24L4.29 11.15a2 2 0 0 1-.52-.38Z',
    },
  ],
];

export default PlaneLanding;
