import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name martini
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOCAyMmg4IiAvPgogIDxwYXRoIGQ9Ik0xMiAxMXYxMSIgLz4KICA8cGF0aCBkPSJtMTkgMy03IDgtNy04WiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/martini
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Martini: IconNode = [
  ['path', { d: 'M8 22h8' }],
  ['path', { d: 'M12 11v11' }],
  ['path', { d: 'm19 3-7 8-7-8Z' }],
];

export default Martini;
