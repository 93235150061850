import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name train-front
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOCAzLjFWN2E0IDQgMCAwIDAgOCAwVjMuMSIgLz4KICA8cGF0aCBkPSJtOSAxNS0xLTEiIC8+CiAgPHBhdGggZD0ibTE1IDE1IDEtMSIgLz4KICA8cGF0aCBkPSJNOSAxOWMtMi44IDAtNS0yLjItNS01di00YTggOCAwIDAgMSAxNiAwdjRjMCAyLjgtMi4yIDUtNSA1WiIgLz4KICA8cGF0aCBkPSJtOCAxOS0yIDMiIC8+CiAgPHBhdGggZD0ibTE2IDE5IDIgMyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/train-front
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TrainFront: IconNode = [
  ['path', { d: 'M8 3.1V7a4 4 0 0 0 8 0V3.1' }],
  ['path', { d: 'm9 15-1-1' }],
  ['path', { d: 'm15 15 1-1' }],
  ['path', { d: 'M9 19c-2.8 0-5-2.2-5-5v-4a8 8 0 0 1 16 0v4c0 2.8-2.2 5-5 5Z' }],
  ['path', { d: 'm8 19-2 3' }],
  ['path', { d: 'm16 19 2 3' }],
];

export default TrainFront;
