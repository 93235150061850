import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name clock-arrow-up
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTMuMjI4IDIxLjkyNUExMCAxMCAwIDEgMSAyMS45OTQgMTIuMzM4IiAvPgogIDxwYXRoIGQ9Ik0xMiA2djZsMS41NjIuNzgxIiAvPgogIDxwYXRoIGQ9Im0xNCAxOCA0LTQgNCA0IiAvPgogIDxwYXRoIGQ9Ik0xOCAyMnYtOCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/clock-arrow-up
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ClockArrowUp: IconNode = [
  ['path', { d: 'M13.228 21.925A10 10 0 1 1 21.994 12.338' }],
  ['path', { d: 'M12 6v6l1.562.781' }],
  ['path', { d: 'm14 18 4-4 4 4' }],
  ['path', { d: 'M18 22v-8' }],
];

export default ClockArrowUp;
