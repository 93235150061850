import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name calendar-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNC4yIDQuMkEyIDIgMCAwIDAgMyA2djE0YTIgMiAwIDAgMCAyIDJoMTRhMiAyIDAgMCAwIDEuODItMS4xOCIgLz4KICA8cGF0aCBkPSJNMjEgMTUuNVY2YTIgMiAwIDAgMC0yLTJIOS41IiAvPgogIDxwYXRoIGQ9Ik0xNiAydjQiIC8+CiAgPHBhdGggZD0iTTMgMTBoNyIgLz4KICA8cGF0aCBkPSJNMjEgMTBoLTUuNSIgLz4KICA8cGF0aCBkPSJtMiAyIDIwIDIwIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/calendar-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CalendarOff: IconNode = [
  ['path', { d: 'M4.2 4.2A2 2 0 0 0 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 1.82-1.18' }],
  ['path', { d: 'M21 15.5V6a2 2 0 0 0-2-2H9.5' }],
  ['path', { d: 'M16 2v4' }],
  ['path', { d: 'M3 10h7' }],
  ['path', { d: 'M21 10h-5.5' }],
  ['path', { d: 'm2 2 20 20' }],
];

export default CalendarOff;
