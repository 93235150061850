import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cloud-snow
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAxNC44OTlBNyA3IDAgMSAxIDE1LjcxIDhoMS43OWE0LjUgNC41IDAgMCAxIDIuNSA4LjI0MiIgLz4KICA8cGF0aCBkPSJNOCAxNWguMDEiIC8+CiAgPHBhdGggZD0iTTggMTloLjAxIiAvPgogIDxwYXRoIGQ9Ik0xMiAxN2guMDEiIC8+CiAgPHBhdGggZD0iTTEyIDIxaC4wMSIgLz4KICA8cGF0aCBkPSJNMTYgMTVoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xNiAxOWguMDEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/cloud-snow
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CloudSnow: IconNode = [
  ['path', { d: 'M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242' }],
  ['path', { d: 'M8 15h.01' }],
  ['path', { d: 'M8 19h.01' }],
  ['path', { d: 'M12 17h.01' }],
  ['path', { d: 'M12 21h.01' }],
  ['path', { d: 'M16 15h.01' }],
  ['path', { d: 'M16 19h.01' }],
];

export default CloudSnow;
