import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name regex
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTcgM3YxMCIgLz4KICA8cGF0aCBkPSJtMTIuNjcgNS41IDguNjYgNSIgLz4KICA8cGF0aCBkPSJtMTIuNjcgMTAuNSA4LjY2LTUiIC8+CiAgPHBhdGggZD0iTTkgMTdhMiAyIDAgMCAwLTItMkg1YTIgMiAwIDAgMC0yIDJ2MmEyIDIgMCAwIDAgMiAyaDJhMiAyIDAgMCAwIDItMnYtMnoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/regex
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Regex: IconNode = [
  ['path', { d: 'M17 3v10' }],
  ['path', { d: 'm12.67 5.5 8.66 5' }],
  ['path', { d: 'm12.67 10.5 8.66-5' }],
  ['path', { d: 'M9 17a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2z' }],
];

export default Regex;
